<template>
 <base-main-container>
    <template v-slot:fluid>

            <v-data-table
             elevation="0" 
        :headers="headers" 
        :items="updatedResources" 
        :items-per-page="10"
        fixed-header 
        height="100%" 
        :search="search" 
        :loading="ResourcesLoading" 
        loading-text="Loading... Please wait"
  >
  
 <template v-slot:top>
    <v-toolbar flat class="rounded-lg">
      <v-toolbar-title>My Resourses</v-toolbar-title>
      <v-spacer />

        <!-- <v-toolbar-title>
          <v-btn small color="success" outlined link to="newresource">
            <v-icon class="mr-2"> mdi-folder-plus</v-icon> Generate New
          </v-btn>
           
        </v-toolbar-title> -->
         <v-toolbar-items> <v-spacer />
           
          </v-toolbar-items>
          
    </v-toolbar>
        <v-toolbar class="mb-6">
         
          <v-select
          
              class="mt-6 mr-2"
              append-icon="mdi-filter"
              v-model="filterType"
              multiple
              :items="types"
              label="Type"
              dense
              outlined
              clearable
            />
                     <v-select
          
              class="mt-6"
              append-icon="mdi-filter"
              v-model="folderFilter"
              multiple
              :items="folders"
              label="Tags"
              dense
              outlined
              clearable
            />
             <v-select
              class="mt-6 ml-2"
               append-icon="mdi-sort"
              v-model="sortby"
              :items="['Title','Type', 'Topic', 'Date']"
          
              label="Sort by"
              dense
              outlined
             
              @change="sort()"
            />
           
<v-text-field
            class="ml-6"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
          />
       </v-toolbar>
</template>
<template
      v-slot:[`item.lastsaved`]="{ item }"

      >
      <v-chip>
       
            {{new Date(item.lastsaved).toLocaleDateString('en-US')}}
      </v-chip>

    </template>
<template
      v-slot:[`item.actions`]="{ item }"
      >
      
      <div v-if="!item.resource"> <h3 style="color:dodgerblue"> 
        <v-progress-linear indeterminate color="primary" ></v-progress-linear>
        Preparing...  
       <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                 <v-btn
                class="ml-3"
              
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  elevation="4"
                  
                  small
                  icon
                  @click="DeleteView(item)"
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
            </h3>
      </div>
      <div v-else-if="!item.folder" @click="view(item)"> 
        <v-btn small rounded> 
        <h3 style="color:#006400"> Ready </h3>  <v-icon color="#006400">mdi-launch</v-icon> 
      </v-btn>
      </div>
      
        <div v-else>      
      <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="ml-3"
              
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                  elevation="4"
                  
                  small
                  icon
                  @click="view(item)"
                >
                  <v-icon>mdi-launch</v-icon>
                </v-btn>

                </template>
              <span>View</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                 <v-btn
                class="ml-3"
              
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  elevation="4"
                  
                  small
                  icon
                  @click="DeleteView(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
             </div>
      </template>
</v-data-table>

       
<v-dialog v-model="deleteDialog" max-width="400" persistent >
<v-card>
  <v-toolbar dark color="red"
  >
    <v-toolbar-title> Delete</v-toolbar-title>
  </v-toolbar>
  <v-card-text class="mt-3">
    Delete selected {{ selectedItem.what }}
  </v-card-text>
  <v-card-actions>
    <v-spacer />
    <v-btn
    outlined
    @click="deleteDialog=false"
    >Cancel</v-btn>
    <v-btn
color="red" outlined
    @click="Delete()">Delete</v-btn>
  </v-card-actions>
</v-card>
    </v-dialog>
    </template>
 </base-main-container>
</template>

<script>
import { db } from "../firebase";
import {firebaseAuth } from "../firebase";

export default {
  

  data() {
    return {
      isLoading: false,
      responseDialog: false,
      search: "",
      resources:[],
      filterType:[],
      folderFilter:[],
      folders:[],
      sortby:"",
      types:[],
      selectedItem:{},
      deleteDialog:false,
     
      
    };
  },
// watch: {
//     "resources": function (val) {
//       this.resources = val;
//     },
//   },
 computed: {
  updatedResources(){
    return this.resources
  },
  headers() {
      let headers=[
          { text: "Title", value: "title" , sortable: false},
            { text: "Type", value: "what" , 
        filter: (value) => {
            if (this.filterType.length<1) return true;
            return this.filterType.includes(value);
          },
        sortable: false},
        { text: "Tag", value: "folder" , 
        filter: (value) => {
            if (this.folderFilter.length<1) return true;
            return this.folderFilter.includes(value);
          },
        sortable: false},
      
        { text: "Topic", value: "topic" , sortable: false},
      //  { text: "Grade", value: "grade" , sortable: false},
        { text: "Last modified", value: "lastsaved" , sortable: false},
        { text: "Actions", value: "actions", sortable: false }
      ]

       return headers;
    },
   ResourcesLoading (){
       return this.resources.length > 0 ? false : true;
     },

 },

  methods: {
   
   async view(item){
    //  this.isLoading = true
      if(item.title){
      this.$store.commit("updateedited", {...item});
       this.$router.push('/editor'); 
    //  this.$router.push({ path:'/editor', query: { data: item.id }}); 
    //  this.isLoading =false
 }
 else {
  let uid = firebaseAuth.currentUser.uid;
  item.title = "No Title"
  item.folder = "No Tags"
 try {
          const userRef = db.collection("customers").doc(uid);
          console.log(uid);
          const subcollectionRef = userRef.collection("saved").doc(item.id);

          await subcollectionRef.update(item);

         
        } catch (error) {
          console.error("Error adding document:", error);
        }
       this.$store.commit("updateedited", {...item});
       this.$router.push('/editor');    
 }
  
    },
    DeleteView(item){
      this.selectedItem = {...item}
      this.deleteDialog = true
    },
    async Delete(){
       let uid = firebaseAuth.currentUser.uid
      try{
        await db.collection('customers').doc(uid).collection("saved").doc(this.selectedItem.id).delete()
      // .then(()=>{
      //   this.updateResources()
      // }

      // )
    }
      catch (error) {
    console.error('Error adding document:', error);
  }
  this.deleteDialog =false
     },

//    async updateResources(){
//     this.resources=[...[]]
//       let uid = firebaseAuth.currentUser.uid
//      try {
// await db.collection('customers').doc(uid).collection("saved").get().then((res)=>{
// res.forEach((doc)=> {
//     // You can add the document ID to the object if needed
//     var docData = doc.data();
//     docData.id = doc.id;

//     // Add the document data to the array
//     this.resources.push(docData);
//     this.sort()
//   });
// })
  
//    } catch (error) {
//     console.error('Error adding document:', error);
//   }
//   this.folders =  [...new Set(this.resources.map(item => item.folder))];
//   this.types =  [...new Set(this.resources.map(item => item.what))];
//     },
   
//sort
sort() {
  if (this.sortby === 'Type') {
    this.resources.sort((a, b) => {
      let atype = a.what || '';
      let btype = b.what || '';
      return atype.toLowerCase().localeCompare(btype.toLowerCase());
    });
  } else if (this.sortby === 'Topic') {
    this.resources.sort((a, b) => {
      let atopic = a.topic || '';
      let btopic = b.topic || '';
      return atopic.toLowerCase().localeCompare(btopic.toLowerCase());
    });
  } else if (this.sortby === 'Title') {
    this.resources.sort((a, b) => {
      let atitle = a.title || '';
      let btitle = b.title || '';
      return atitle.toLowerCase().localeCompare(btitle.toLowerCase());
    });
  } else { // assuming 'lastsaved' is a Date type
    this.resources.sort((a, b) => {
      let adate = a.lastsaved ? a.lastsaved : 0
      let bdate = b.lastsaved ? b.lastsaved : 0
      return  bdate - adate;
    });
  }
}


//sort end




  },
  mounted (){
 // this.updateResources()
 },
 created() {
   
    let uid = firebaseAuth.currentUser.uid
    const ref = db.collection('customers').doc(uid).collection("saved")

    ref
      .onSnapshot(querySnapshot => {
        var updated = [];
        querySnapshot.forEach(doc => {
          var docData = doc.data();
           docData.id = doc.id;
           updated.push(docData);
        });
        this.resources= [...updated]
        this.sortby= "Date"
        this.sort()
        this.folders =  [...new Set(this.resources.map(item => item.folder))];
        this.types =  [...new Set(this.resources.map(item => item.what))];
      });
  }
  
};
</script>
<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    @media (min-width: 600px) {
  .my-card {
     border-radius: 15px;  
    width: 400px;
  }
}
@media (min-width: 960px) {
  .my-card {
     border-radius: 15px;  
    width: 600px;
  }
}
@media (min-width: 1264px) {
  .my-card {
     border-radius: 15px;  
    width: 900px;
  }
}
    .container.fill-height {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
}

</style>




