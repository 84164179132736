<template>
  <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>

      <template v-slot:centered>
      <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title>I wish to know: 
        
        </v-card-title>
        <v-card-text>
            <!-- <v-select v-if="!premium"
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="what"
            :items="[
              'What is', 
              
            ]"
            label="Choose what you wish"
            outlined
            clearable
            @change="clearSelections()"
          >
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
                    Whay is (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
                    How to (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
                   When was (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
                    'Where is' (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
                  Ask anything you want to know (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              
            </template>
          </v-select> -->
          <v-select 
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="what"
            :items="[
              'What is', 
              'How to',
              'Why is',
              'When was',
              'Where is',
              'Ask anything you want to know'
            ]"
            label="Choose what you wish"
            outlined
            clearable
             @change="clearSelections()"
          ></v-select>

          <v-text-field
          v-if="what"
              v-model="topic"
            class="ml-2"
            :label="what == 'What is' ? 
            'Solar eclips, etc.':
            what == 'How to' ? 
            'hatch chicken eggs, etc.':
            what == 'What is' ? 
            '':
            what == 'When was' ? 
            'the paper money used, etc.':
            what == 'Ask anything you want to know' ? 
            'Feel free to ask me anything':
            what == 'Where is' ? 
            'Atlantis, etc.':''
            "
            outlined
          />
          
         
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn
           
            color="#212121"
            @click="sendRequest()"
          >
           
            <h3 style="color: white">Generate please</h3>
          </v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
      </template>
   <v-snackbar
          v-model="showSnackbar"
           :timeout="6000"
           color="warning"
          top
          
        >
          {{ snackbarMessege }}
        </v-snackbar>
  </base-main-container>
</template>

<script>

import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseAuth } from "../firebase";
import { db } from "../firebase";
export default {
 
  data() {
    return {
      what: null,
      topic: null,
      
      receivedPlan: "",
      isLoading: false,
      resources:[],

      showSnackbar:false,
      snackbarMessege:"",
     
    };
  },
  computed:{
   premium(){
      return this.$store.state.subscription ? this.$store.state.subscription.status =='active' 
      || this.$store.state.subscription.status == 'active school'
       || this.$store.state.subscription.status == 'trialing'
      : false
    }
  },
  methods: {
  
    async sendRequest() {
      this.isLoading = true;
     
   
      let prompt = this.what == 'Ask anything you want to know' ?
      this.topic :
      this.what+" "+ this.topic

      let uid = firebaseAuth.currentUser.uid;
      let newresource ={
    what: this.what,
        topic: this.topic,
        grade: " ",
        lastsaved: Date.now(),
        promt: [{ "role": "system", "content": "You are an excellent educator and teacher." },
    { "role": "user", "content": prompt }],
        uid:uid
}
try {
          const userRef = db.collection("customers").doc(uid);
          const subcollectionRef = userRef.collection("saved");

          const docRef = await subcollectionRef.add(newresource)
          //console.log(docRef.id);
          newresource.id=docRef.id
        } catch (error) {
          console.error("Error adding document:", error);
        }

const functions = getFunctions();

      try { 
      const functionRef = httpsCallable(
        functions,
        "callopenai"
      );
      functionRef(newresource);
       
        this.isLoading = false;
        this.saveDialog = false;
        this.$router.push("/mydocs");

}catch (error) {
      this.isLoading = false;
      this.showSnackbar = true
      this.snackbarMessege = "The system is overloaded, please try again" 
}


    },
    copy() {
      navigator.clipboard.writeText(this.receivedPlan);
    },
    
    clearSelections() {
      (this.topic = null),
        (this.grade = null),
        (this.genre = null),
        (this.tone = null),
        (this.characters = null),
        (this.voice = null),
        (this.letterTopics = null),
        (this.language = null),
        (this.quizType = null),
        (this.quizItemCount = null),
        (this.imgcheck = false),
        (this.learningStandards = null);
    },
     
  },
  
};
</script>
<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    @media (min-width: 600px) {
  .my-card {
     
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
     
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
    
    width: 800px;
  }
}
    .container.fill-height {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}


</style>




