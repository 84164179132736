var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"what-can-do",attrs:{"id":"what-can-do"}},[_c('h1',[_vm._v("What Can GPT4Teachers Do?")]),_c('div',{staticClass:"blocks-wrapper"},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"block-left-side"},[_c('v-icon',{attrs:{"size":"70","color":"purple darken-2"}},[_vm._v(" mdi-robot-outline ")])],1),_vm._m(0)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"block-left-side"},[_c('v-icon',{attrs:{"size":"70","color":"success"}},[_vm._v(" mdi-chat-outline ")])],1),_vm._m(1)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"block-left-side"},[_c('v-icon',{attrs:{"size":"70","color":"pink darken-2"}},[_vm._v(" mdi-web ")])],1),_vm._m(2)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"block-left-side"},[_c('v-icon',{attrs:{"size":"70","color":"info darken-2"}},[_vm._v(" mdi-content-save-outline ")])],1),_vm._m(3)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"block-left-side"},[_c('v-icon',{attrs:{"size":"70","color":"purple darken-2"}},[_vm._v(" mdi-pencil-outline ")])],1),_vm._m(4)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"block-left-side"},[_c('v-icon',{attrs:{"size":"70","color":"purple darken-2"}},[_vm._v(" mdi-lightbulb-outline ")])],1),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-right-side"},[_c('h3',[_vm._v("AI-Powered Resource Generation")]),_c('p',[_vm._v(" Utilizing advanced AI technology, GPT4Teachers.com allows teachers to create unique and effective resources for teaching, making lesson planning more efficient and personalized. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-right-side"},[_c('h3',[_vm._v("Communication Material Creation")]),_c('p',[_vm._v(" Beyond teaching resources, the platform also helps generate communication materials. These could range from letters to parents, email templates, or recommendation letters, all aiding in effective communication within the school community. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-right-side"},[_c('h3',[_vm._v("Multilingual Support")]),_c('p',[_vm._v(" GPT4Teachers.com can translate these resources into multiple languages with just a click, ensuring the material is accessible to diverse student populations and global educational communities. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-right-side"},[_c('h3',[_vm._v("Save and Reuse")]),_c('p',[_vm._v(" The platform provides the ability for teachers to save their generated resources. This allows for easy future reference and reuse, optimizing their time and effort. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-right-side"},[_c('h3',[_vm._v("Editable Resources")]),_c('p',[_vm._v(" All resources generated can be edited according to the specific needs of the teachers, ensuring each piece of material is perfectly suited to their teaching style and their students' learning needs. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-right-side"},[_c('h3',[_vm._v("Unlimited Opportunities")]),_c('p',[_vm._v(" By harnessing the power of AI, GPT4Teachers.com opens up limitless possibilities for educators, allowing them to innovate in their teaching methods, engage their students more effectively, and enhance the overall learning experience in their classrooms. ")])])
}]

export { render, staticRenderFns }