<template>
<base-main-container>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="60" class="text-center">
        Loading please wait
      </v-progress-circular>
    </v-overlay>

    <!-- your content here -->
    <template v-slot:fluid>
      <v-card elevation="0">
        <!-- <v-card-title>
          Your Subscription Status
        </v-card-title> -->
        <v-card-text v-if="$store.state.subscription ? $store.state.subscription.status == 'active school' : false">
          <h2>You have school licenced account. </h2>
          <br />
          <br />
          <h3> School Name:</h3>
          {{ $store.state.subscription.school_name }}
          <br /><br />
          <h3> Expire:</h3>
          {{ $store.state.subscription.current_period_end }}
          <br/>
          

        </v-card-text>
        <v-card-text v-else-if="$store.state.subscription ? 
        $store.state.subscription.status == 'active'
        : false">
          <h2> You have and active subscription</h2>
          <br />
          <br />
          
          <v-chip @click="openCustomerPortal">
            <v-icon>mdi-account-settings</v-icon>
            Manage Subscription at Stripe
          </v-chip>
        </v-card-text>
         <v-card-text v-else-if="$store.state.subscription ? 
        $store.state.subscription.status == 'trialing'
        : false">
          <h2> You subscription is in trial mode</h2>
          <br />
          <br />
          <v-chip @click="openCustomerPortal">
            <v-icon>mdi-account-settings</v-icon>
            Manage Subscription at Stripe
          </v-chip>
        </v-card-text>
        <v-card-text v-else-if="!stripeAccount">
          <pricing></pricing>
        </v-card-text>
      </v-card>
      <v-divider></v-divider>
      <v-card v-if="stripeAccount">
         <v-card-text>
          <h2> You have or had a subscription via Stripe</h2>
          <br />
          <h3> Your Stripe account status is: </h3> 
          <br />
          <v-chip color="primary"> {{ stripeStatus }} </v-chip>
         
          <br />
          <br />
          <v-chip color="green" @click="openCustomerPortal">
            <v-icon>mdi-account-settings</v-icon>
            Manage Subscription at Stripe
          </v-chip>
        </v-card-text>
      </v-card>
    </template>
  </base-main-container>
            <!-- <v-container  fluid fill-height class="centered" >
                 <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="60" class="text-center">
        Loading please wait
      </v-progress-circular>
    </v-overlay>
       
        <v-card elevation="0" class="ma-10" width="100%">
          <v-card-title>
        Your Subscription Status
          </v-card-title>
          <v-card-text v-if="$store.state.subscription ? $store.state.subscription.status == 'active school' : false">
        <h2>Your account is a school account. </h2>  
         <br/>
         <br/>
        <h3> School Name:</h3>
          {{$store.state.subscription.school_name  }} 
          <br/><br/>
          <h3>  Expire:</h3>
             {{$store.state.subscription.current_period_end}}
        </v-card-text>
          <v-card-text v-else-if="$store.state.subscription.status == 'active'">
 
            <h2> You have and active subscription</h2>
            <br/>
             <br/>
            

 <v-chip
@click="openCustomerPortal"
  >
    <v-icon>mdi-account-settings</v-icon>
      Manage Subscription at Stripe
  </v-chip>

</v-card-text>
          <v-card-text v-else>
You dont have subscription, please subscribe
  <v-card elevation="5" width="300" class="pa-4 text-center">
       <v-toolbar dark> 
        <v-toolbar-title>Subscribe</v-toolbar-title>
                    
                  </v-toolbar>
     
       
              <v-btn large rounded color="green" @click="createSub()" class="mt-8">
            <v-icon dark class="mr-2">mdi-hand-pointing-right</v-icon> Only $7.99/month
          </v-btn>
          <p>You get 3 days free trial</p>
          <p class="mt-4">Monthly subscription, cancel anytime, Payments managed via Stripe.</p>
           
              </v-card> 
          </v-card-text>
    
        </v-card>

      </v-container> -->
       
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { firebaseAuth } from "@/firebase";
import pricing from '../newfacecomponents/pricing_v2.vue'
export default {
     components: {
pricing
     },
  data: () => ({
    stripeAccount:false,
    stripeStatus:"",
    isLoading:false,
    montlyPrice_799_code:"price_1MlNCVIuZFpfQ8DRIbX9PShJ",
     
  }),
methods: {
    async createSub() {
      this.isLoading = true;
      const db = getFirestore();
      const collectionRef = collection(
        db,
        "customers",
        firebaseAuth.currentUser.uid,
        "checkout_sessions"
      );

      const docRef = await addDoc(collectionRef, {
        price: this.montlyPrice_799_code,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();

        if (error) {
          console.error(`An error occured: ${error.message}`);
          this.isLoading = false;
        }

        if (url) {
          window.location.assign(url);
        }
      });
    },
   
    async openCustomerPortal() {
      this.isLoading = true;

      const functions = getFunctions();
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );
      const { data } = await functionRef({
        returnUrl: window.location.origin,
      });

      window.location.assign(data.url);
    }
},
async created() {
      const dbs = getFirestore();
      const subsRef = collection(
        dbs,
        "customers", firebaseAuth.currentUser.uid, "subscriptions"
      );
      console.log(subsRef)
      
      let subsQuery = query(
        subsRef,
        where("status", "not-in", ["trialing", "active"])
      );

      await getDocs(subsQuery)
        .then((sub) => {
      if(sub.docs.length > 0){
              console.log("this user has or had stripe account")
              console.log(sub.docs[0].data().status)
              this.stripeAccount = true
              this.stripeStatus = sub.docs[0].data().status
 
            } else {
              console.log("this user dont have stripe account")
 
          }
        
        })
      }
 
}
</script>
<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    .container.fill-height {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
}


</style>

