<template>
  <base-main-container>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="68">
        AI is preparing your resource
      </v-progress-circular>
    </v-overlay>

    <template>
      <v-card class="v-card--custom">
        <v-card-title>Your Editable Resource: </v-card-title>

        <v-card-text style="height: 79%">
          <tinymce-editor ref="editor" :api-key="apiKey" :init="initOptions" v-model="editorContent" :key="isDarkTheme" />
        </v-card-text>
<v-card-actions>
  <v-text-field v-model="regenerateReq" outlined label="Regenerate" hint="Please tell me what to change or improve? "
   @keydown.enter="regenerate()"
  >
   <template v-slot:append> 
    <v-btn 
    :disabled="!regenerateReq"
    class="mt-n2" @click="regenerate()"><v-icon  color="green"> mdi-reload </v-icon>  Regenerate</v-btn>  
        
    
  </template>
</v-text-field>
  <!-- <v-btn color="grey" class="v-btn-regenerate--custom" outlined>
            <v-icon>mdi-reload</v-icon>
            Regenerate response
          </v-btn> -->
</v-card-actions>
        <v-card-actions>
              <v-btn color="warning" class="mx-1" min-width="40" 
              outlined link to="mydocs" >
             <v-icon class="mr-2">mdi-arrow-left-circle</v-icon> Back
              </v-btn>

              <v-btn color="info" class="mx-1" min-width="40" 
              outlined @click="translateDialog = true" >
                <v-icon class="mr-2">mdi-translate-variant</v-icon> Translate
              </v-btn>
              <v-btn color="info" class="mx-1" min-width="40" 
              outlined @click="diffDialog = true" >
                <v-icon class="mr-2">mdi-account-group</v-icon> Differentiate
              </v-btn>
           
          
              <v-btn color="info" class="mx-1" min-width="40" 
              outlined @click="print()" >
                <v-icon class="mr-2">mdi-printer-outline</v-icon> Print
              </v-btn>
            
         
              <v-btn color="success" class="mx-1" min-width="40" 
              outlined @click="saveDialog = true, updateResources()">
                <v-icon class="mr-2">mdi-content-save-all-outline</v-icon>
              Save</v-btn>

               <!-- <v-btn color="success" class="mx-1" min-width="40" 
              outlined @click="downloadPPT()">
                <v-icon class="mr-2">mdi-content-save-all-outline</v-icon>
              Download PPT</v-btn> -->
          
          

          <!-- <v-chip @click="copyToClipboard()">Copy</v-chip> -->
        </v-card-actions>
      </v-card>
      <v-card ref="translationCard" v-if="translation.length > 1" class="mt-4 v-card--custom" height="100%">
        <v-card-title> Translation/Diffrentiation </v-card-title>
        <v-card-text style="height: 79%">
          <tinymce-editor ref="editor" :api-key="apiKey" :init="initOptions" v-model="translation" :key="isDarkTheme"/>
        </v-card-text>
        <v-card-actions>
         
              <v-btn color="info" class="mx-1" min-width="40" 
              outlined @click="printTranslate()" v-bind="attrs">
                <v-icon class="mr-2">mdi-printer-outline</v-icon>
             Print </v-btn>
           
         
        </v-card-actions>
      </v-card>
    </template>

    <v-dialog v-model="saveDialog" max-width="400">
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title> Save </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-text-field v-model="saveTitle" label="Give a Title (optional)"></v-text-field>

          <v-combobox v-model="saveFolder" :items="folders" label="Tag this file (optional)" class="mx-2"
            @input.native="saveFolder = $event.srcElement.value">
          </v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="saveDialog = false">Cancel</v-btn>
          <v-btn 
          :disabled="resources.length>5 && !premium"
          @click="Save()">Save</v-btn>
        </v-card-actions>
        <v-card-actions> 

        </v-card-actions>
        <v-card-actions v-if="resources.length>5 && !premium">
          <h5> You have reach free account limit, please get Premium subscription to save</h5>

        </v-card-actions>
      
      </v-card>
    </v-dialog>
    <v-dialog v-model="compareDialog" scrollable>
      <v-card>
        <v-card-title> Compare </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
               <v-card>
    <v-card-title>Current Version</v-card-title>
    <v-card-text>
      <div v-html="editorContent"></div>
    </v-card-text>
  </v-card>
            </v-col>
             <v-col cols="6">
             <v-card>
    <v-card-title>Regenereted Version</v-card-title>
    <v-card-text>
      <div v-html="regeneretedContent"></div>
    </v-card-text>
  </v-card>

            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="compareDialog=false"> Cancel</v-btn>
          <v-btn @click="acceptRegeneration()"> Accept Regeneration</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog v-model="translateDialog" max-width="400">
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title> Translate </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="12">
              <v-combobox class="mx-4 mb-n8" v-model="language" :items="languages"
                label="Type or choose any language to translate" @input.native="language = $event.srcElement.value">
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="translateDialog = false">Cancel</v-btn>
          <v-btn 
          :disabled="!premium || !language"
          @click="translate()">Translate</v-btn>
          
        </v-card-actions>
        <v-card-actions>
<div v-if="!premium" class="text-red-lighten-1">
   <h5>Please get Premium to use translation service </h5>
          
</div> 
         
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="diffDialog" max-width="400">
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title> Differentiate </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="12">
              <v-select
              v-model="diffGroup"
              label="Select the type"
              :items="['IEP', 'ENL', 'Culturally Responsive', 'Ability' ]"
              >  
              </v-select>
            
              <v-text-field
              v-model="diffDetail"
              label="Please give me more details about the student group"
              > </v-text-field>
            </v-col>

          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="diffDialog = false">Cancel</v-btn>
          <v-btn 
          @click="differentiate()">Differentiate</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="showSnackbar" :timeout="6000" color="warning" top>
      {{ snackbarMessege }}
    </v-snackbar>
  </base-main-container>
</template>

<script>
import { mapGetters } from "vuex";
import Editor from "@tinymce/tinymce-vue";
import { db } from "../firebase";
import { firebaseAuth } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import PptxGenJS from "pptxgenjs";
export default {
  components: {
    "tinymce-editor": Editor,
    // TiptapVuetify,
    // Vue2TinymceEditor
  },
  data() {
    return {
      regenerateReq:"",
      submenuOpen: false,
      apiKey: "l1m5hajvu20yhf6361yxt3way6gv8dqyctxcm0mrq2ge9ndy",
      isLoading: false,
      saveDialog: false,
      diffDialog: false,
      translateDialog: false,
      compareDialog:false,
      saveTitle: this.$store.state.edited.title,
      saveFolder: this.$store.state.edited.folder,
      folders: [],
      diffDetail: "",
      diffGroup:"",
      drawer: null,
      darkTheme: false,
      editorContent: this.$store.state.edited.resource,
      regeneretedContent:"",
      prompt:[
         { "role": this.$store.state.edited.promt[0].role, "content": this.$store.state.edited.promt[0].content},
         { "role": this.$store.state.edited.promt[1].role, "content": this.$store.state.edited.promt[1].content}
  
      ],
      diffprompt:[
         { "role": this.$store.state.edited.promt[0].role, "content": this.$store.state.edited.promt[0].content},
         { "role": this.$store.state.edited.promt[1].role, "content": this.$store.state.edited.promt[1].content}
  
      ],
      resources: [],
      translation: "",
      language: "",
      showSnackbar: false,
      snackbarMessege: "",
      languages: [
        "Spanish",
        "French",
        "German",
        "Italian",
        "Russian",
        "Chinese",
        "Japanese",
        "Korean",
        "Arabic",
        "Turkish",
        "Hindi",
      ],
      // id: null,
     
    };
  },
  watch: {
    "$store.state.edited.title": function (val) {
      this.saveTitle = val;
    },
    "$store.state.edited.folder": function (val) {
      this.saveFolder = val;
    },
    "$store.state.edited.resource": function (val) {
      this.editorContent = val;
    },
    
  },

  computed: {
    ...mapGetters('switchTheme', ['isDarkTheme']),
    initOptions() {
      return {
        height: "100%",
        menubar: false,
        /* some plugins and toolbar options */
        skin: this.isDarkTheme ? "oxide-dark" : "oxide",
        content_css: this.isDarkTheme ? "dark" : "default",
        selector: 'textarea',  // change this value according to your HTML
        resize: false,
        toolbar:
          "undo redo | formatselect | fontselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | fullscreen code",
      };
    },
 premium(){
      return this.$store.state.subscription ? this.$store.state.subscription.status =='active' 
      || this.$store.state.subscription.status == 'active school'
       || this.$store.state.subscription.status == 'trialing'
      : false
    }
  },
  
  methods: {
   async differentiate(){
      this.isLoading = true;
      let group = this.diffGroup;
      let details = this.diffDetail

      let obj3={ "role": "assistant", "content": this.editorContent}
   let obj4= { "role": "user", "content":"Thank you, I have a group of students that needs differentiated version of this"}
   let obj5={"role": "assistant", "content":"Yes, I can help you with that, can you tell me what type of students you have" }
    let obj6= { "role": "user", "content":"Differentition that I need is for "+ group}
   let obj7={"role": "assistant", "content":"Thank you, can you give me more detals about this group " }
  let obj8= { "role": "user", "content":details}
   
  //  let  prompt= []
this.diffprompt.push(obj3, obj4, obj5, obj6, obj7, obj8)

      const functions = getFunctions();

      try {
        const functionRef = httpsCallable(functions, "callopenaiForDiff");
        const { data } = await functionRef({
          promt: this.diffprompt,
        });

        this.translation = data.result;
        setTimeout(1000);
        this.isLoading = false;
        this.diffDialog = false;
        this.$nextTick(() => {
          console.log('Trying to scroll'); 
            this.$refs.translationCard.$el.scrollIntoView({ behavior: 'smooth' });
          });
     //    this.$refs.translationCard.$el.scrollIntoView({ behavior: 'smooth' });
      } catch (error) {
        this.isLoading = false;
        this.translation = "";
        this.diffDialog = false;
        this.showSnackbar = true;
        this.snackbarMessege = "The system is overloaded, please try again";
      }
    },
    
    blank(){
      var pptx = new PptxGenJS();
       console.log(pptx)
    },
   
  async  downloadPPT(){
    this.isLoading = true;
   
    let PPTcode=""
      let prompt = this.editorContent
  var pptx = new PptxGenJS();
      let SimpleSlideTemp =`
//add first slide
var slide1 = pptx.addSlide();

//Reasons Why Your Child is Avoiding Math
slide1.addText("Reasons Why Your Child is Avoiding Math", { x: 0.5, y: 0.5, fontSize: 26, bold: true });

//add next slide
var slide2 = pptx.addSlide();

//1. Lack of Interest
slide2.addText("1. Lack of Interest", { x: 0.5, y: 0.5, fontSize: 26, bold: true });
slide2.addText("- Your child may not find math engaging or challenging enough", { x: 1.0, y: 1.5 });

//add next slide
var slide3 = pptx.addSlide();

//2. Difficulty
slide3.addText("2. Difficulty", { x: 0.5, y: 0.5, fontSize: 26, bold: true });
slide3.addText("- Your child may be struggling to understand math concepts or solve math problems", { x: 1.0, y: 1.5 });
slide3.addText("- This can lead to frustration and lack of motivation to study", { x: 1.0, y: 2.0 });

//add next slide
var slide4 = pptx.addSlide();

//3. Poor Teaching
slide4.addText("3. Poor Teaching", { x: 0.5, y: 0.5, fontSize: 26, bold: true });
slide4.addText("- Your child's teacher may not be able to provide a clear explanation of math concepts", { x: 1.0, y: 1.5 });
slide4.addText("- This can lead to a lack of interest in the subject", { x: 1.0, y: 2.0 });

//add next slide
var slide5 = pptx.addSlide();

//4. Negative Mindset
slide5.addText("4. Negative Mindset", { x: 0.5, y: 0.5, fontSize: 26, bold: true });
slide5.addText("- Your child may think they are not good at math or doesn't enjoy it", { x: 1.0, y: 1.5 });
slide5.addText("- This can create a negative mindset towards the subject and reduce their motivation to study", { x: 1.0, y: 2.0 });

//add next slide
var slide6 = pptx.addSlide();

//Overcoming Disinterest in Math
slide6.addText("Overcoming Disinterest in Math", { x: 0.5, y: 0.5, fontSize: 26, bold: true });
slide6.addText("- Identify the underlying cause of disinterest", { x: 1.0, y: 1.5 });
slide6.addText("- Try different teaching methods", { x: 1.0, y: 2.0 });
slide6.addText("- Get additional help from a tutor or online resources", { x: 1.0, y: 2.5 });
slide6.addText("- Help your child develop an interest in math", { x: 1.0, y: 3.0 });

//end of slides         
`
  
const functions = getFunctions();

      try {
        const functionRef = httpsCallable(functions, "callopenaiForPPT");
        const { data } = await functionRef({
          promt: prompt,
          template: SimpleSlideTemp,
        });

        PPTcode = data.result;
        this.isLoading = false;
       } catch (error) {
        this.isLoading = false;
        this.showSnackbar = true;
        this.snackbarMessege = "The system is overloaded, please try again";
      }
  // Save the presentation
  console.log(PPTcode)
  const startString = "//add first slide";
const endString = "//end of slides";
  const startIndex = PPTcode.indexOf(startString);
  // if (startIndex === -1) {
  //   return ''; // Start string not found
  // }

  // Find the index of the end string
  const endIndex = PPTcode.indexOf(endString, startIndex + startString.length);
  // if (endIndex === -1) {
  //   return ''; // End string not found
  // }

  // Extract the text between the start and end strings
  const extractedText = PPTcode.substring(startIndex + startString.length, endIndex).trim();

  try{
    eval(extractedText)
   console.log("check2")
pptx.writeFile(this.$store.state.edited.what);
console.log("check3")
//pptx.writeFile();
console.log("check4")
  } catch {
        this.showSnackbar = true;
        this.snackbarMessege = "The system is overloaded, please try again";
  }

    },
    async regenerate(){
   this.isLoading = true;
   let newprompt = [...this.prompt]
  //  let obj1={ "role": this.$store.state.edited.promt[0].role, "content": this.$store.state.edited.promt[0].content}
  //  let obj2={ "role": this.$store.state.edited.promt[1].role, "content": this.$store.state.edited.promt[1].content}
   let obj3={ "role": "assistant", "content": this.editorContent}
   let obj4= { "role": "user", "content":"Thank you, can you improve or make a few changes to  your response"}
   let obj5={"role": "assistant", "content":"Yes, I can, what do you want me to change or improve" }
   let obj6={"role": "user", "content":this.regenerateReq}
  //  let  prompt= []
  newprompt.push(obj3, obj4, obj5, obj6)
//this.prompt.push(obj3, obj4, obj5, obj6)
    // [
    //   this.$store.state.edited.promt[1],this.$store.state.edited.promt[2],
    // // { "role": "system", "content": "You are an excellent educator and teacher." },
    // { "role": "user", "content": this.$store.state.edited.promt },
    // { "role": "assistant", "content": this.editorContent},
    // { "role": "user", "content":this.regenerateReq}
  //]
 // console.log(this.prompt)      
  const functions = getFunctions();

      try {
        const functionRef = httpsCallable(functions, "callopenaiRegenerate");
        const { data } = await functionRef({
         promt:newprompt,
         // promt: this.prompt,
        });
        this.regeneretedContent = data.result;
        // this.editorContent = data.result;
         this.isLoading = false;
         this.compareDialog =true;
        } catch (error) {
        this.isLoading = false;
        
        // this.showSnackbar = true;
        // this.snackbarMessege = "The system is overloaded, please try again";
      }
   this.regenerateReq=""
},
acceptRegeneration(){
  this.editorContent=this.regeneretedContent
  this.compareDialog =false;
},
    async translate() {
      this.isLoading = true;
      let language = this.language;
      let prompt =
        "can you please translate this text to " +
        language +
        " : " +
        this.editorContent;

      const functions = getFunctions();

      try {
        const functionRef = httpsCallable(functions, "callopenaiForTranslation");
        const { data } = await functionRef({
          promt: prompt,
        });

        this.translation = data.result;
        setTimeout(1000);
        this.isLoading = false;
        this.translateDialog = false;
        this.$nextTick(() => {
          console.log('Trying to scroll'); 
            this.$refs.translationCard.$el.scrollIntoView({ behavior: 'smooth' });
          });
     //    this.$refs.translationCard.$el.scrollIntoView({ behavior: 'smooth' });
      } catch (error) {
        this.isLoading = false;
        this.translation = "";
        this.translateDialog = false;
        this.showSnackbar = true;
        this.snackbarMessege = "The system is overloaded, please try again";
      }
    },
    async Save() {
      this.isLoading = true;

      let newresource = {
        what: this.$store.state.edited.what,
        topic: this.$store.state.edited.topic,
        grade: this.$store.state.edited.grade,
        lastsaved: Date.now(),
        resource: this.editorContent,
        folder: this.saveFolder,
        title: this.saveTitle,
      };

      let uid = firebaseAuth.currentUser.uid;

     // if (this.id) {
        try {
          const userRef = db.collection("customers").doc(uid);
          console.log(uid);
          const subcollectionRef = userRef.collection("saved").doc(this.$store.state.edited.id);

          const docRef = await subcollectionRef.update(newresource);

          console.log(docRef);
        } catch (error) {
          console.error("Error adding document:", error);
        }
        this.isLoading = false;
        this.saveDialog = false;
        this.$router.push("/mydocs");
    //  } 
      // else {
      //   try {
      //     const userRef = db.collection("customers").doc(uid);
      //     const subcollectionRef = userRef.collection("saved");

      //     const docRef = await subcollectionRef.add(newresource)
      //     console.log(docRef.id);
      //     // this.id=docRef.id
      //   } catch (error) {
      //     console.error("Error adding document:", error);
      //   }
      //   this.isLoading = false;
      //   this.saveDialog = false;
      //   this.$router.push("/mydocs");
      // }
    },



    async updateResources() {
     
      this.resources = [...[]];
      let uid = firebaseAuth.currentUser.uid;
      try {
        await db
          .collection("customers")
          .doc(uid)
          .collection("saved")
          .get()
          .then((res) => {
            res.forEach((doc) => {
              // You can add the document ID to the object if needed
              var docData = doc.data();
              docData.id = doc.id;

              // Add the document data to the array
              this.resources.push(docData);
            });
          });
      } catch (error) {
        console.error("Error adding document:", error);
      }
      this.folders = [...new Set(this.resources.map((item) => item.folder))];
    },
    print(){
      // Create a new window with the text content
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`<html><body>${this.editorContent}</body></html>`);
      printWindow.document.close();

      // Print the contents of the new window
      printWindow.print();
    },
    printTranslate(){
       // Create a new window with the text content
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`<html><body>${this.translation}</body></html>`);
      printWindow.document.close();

      // Print the contents of the new window
      printWindow.print();
    },
    copyToClipboard() {
      const textToCopy = `<html><body>${this.editorContent}</body></html>` // Replace with the string you want to copy

      // Create a temporary textarea element
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      textarea.style.position = "fixed"; // Ensure the element is not visible
      document.body.appendChild(textarea);

      // Select and copy the text
      textarea.select();
      document.execCommand("copy");

      // Clean up and remove the temporary element
      document.body.removeChild(textarea);

      // Show a feedback message
      alert("Text copied to clipboard!");
    }
  },
};
</script>
<style scoped>


.theme--light.v-card {
    background-color: #fff;
    color: rgb(221 13 13 / 87%);
}
.v-card--custom {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 18px !important;
  box-shadow: none !important;
}
.v-card--custom .v-card__actions {
  position: relative;
  padding: 16px 0;
  margin-top: auto;
  margin-left: 12px !important;
}

.v-card--custom .v-card__text {
  padding-bottom: 0 !important;
}

.v-btn-regenerate--custom {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.centered {
  border-radius: 15px;
  background-color: #f3f6fc;
  display: flex;
  justify-content: center;
  align-content: baseline;
  max-width: 1200px;
  height: 85vh; /* Adjust this value to match your desired height */
}
@media (min-width: 600px) {
  .my-card {
    width: 300px;
    height: 100%;
  }
}
@media (min-width: 960px) {
  .my-card {
    height: 100%;
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
    height: 100%;
    width: 800px;
  }

}

</style>