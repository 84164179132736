<template>
    <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>
       <template v-slot:centered>
  
    <v-card  class="v-card--custom">
        <v-card-title>My Classes 
             <v-btn small color="green" class="ml-3" @click="addDialog = true">add new</v-btn>
        </v-card-title>

<v-card-text>
      <v-data-table
        :headers="headers"
        :items="classes"
        class="elevation-1 pa-5 mb-15"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon @click="(selecteditem = item), (viewstudentsDialog = true)">
            mdi-playlist-edit</v-icon
          >
        </template>
         <template v-slot:[`item.students`]="{ item }">
          {{ item.students.length }}
        </template>
      </v-data-table>
      </v-card-text>
     
    </v-card>

    <v-dialog v-model="viewstudentsDialog" max-width="800" scrollable>
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-icon @click="viewstudentsDialog = false"
            >mdi-close
          </v-icon></v-card-title
        >
        <v-card-text>
          <v-container>
            <v-card outlined>
                <v-card-title> Add New Student</v-card-title>
                <v-card-text>

               
                <v-text-field
                dense
            v-model="newStudent.studentName"
            label="Student Name"
            />
            <v-text-field
            dense
            v-model="newStudent.studentEmail"
            label="Student Email (username)"
            />
            <v-text-field
            dense
            v-model="newStudent.studentDiff"
            label="Student properties for diffrentiation"
            />
 </v-card-text>
            <!-- <v-text-field v-model="enterstudents" label="New Student" /> -->
           
           
            <v-card-actions>
                <v-spacer />
                 <v-btn dense small :disabled="newStudent.studentEmail.length<3" color="primary" @click="addstudents()">Add</v-btn>
 
            </v-card-actions>
            </v-card>
            <v-divider>
            </v-divider>
            <v-card outlined>
                <v-card-title>Class List 
 <v-btn class="ml-6" small color="green" @click="updateStudent()">Update Changes</v-btn>
                </v-card-title>
                <v-card-text>
<v-list>
              <v-list-item
                v-for="(item, index) in selecteditem.students"
                :key="index"
              >
                <v-list-item-content>
                    <v-list-item-title>
                      {{ item.studentName }} ( {{ item.studentEmail }}) 
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <v-text-field
                        outlined
                        v-model="item.studentDiff"
                        >
                         
                        </v-text-field>
                   
                    </v-list-item-subtitle>
                  
                </v-list-item-content>
                <v-list-item-action>
                       <v-btn color="error" @click="removeStudent(index)">Remove</v-btn>
                
                </v-list-item-action>
              </v-list-item>
            </v-list>
                </v-card-text>
            </v-card>
            
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addDialog" max-width="400" persistent>
      <v-card>
        <v-toolbar dark color="red">
          <v-toolbar-title> Add Class</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-text-field v-model="newClass.className" label="Class Name">
          </v-text-field>
         
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined @click="addDialog = false">Cancel</v-btn>
          <v-btn color="primary" outlined @click="AddNewClass()">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
       </template>
    </base-main-container>
</template>
<script>
import { db } from "../firebase";
import { firebaseAuth } from "../firebase";
// import { getFunctions, httpsCallable } from "firebase/functions";
export default {
  data() {
    return {
      classes: [],
      search: "",
      isLoading: false,
      addDialog: false,
      selecteditem: {},
      viewstudentsDialog: false,
      enterstudents: "",
      newClass: {
        className: "",
        students: [],
      },
      newStudent: {
        studentName: "",
        studentEmail: "",
        studentDiff: "",
    },
    };
  },
  computed: {
    headers() {
      let headers = [
        { text: "Class", value: "className" },
        { text: "Number of Students", value: "students" },
        { text: "Actions", value: "actions" },
      ];
      return headers;
    },
  },
  methods: {
    async addstudents() {
     
          let addStudent = {...this.newStudent}
          this.selecteditem.students.push(addStudent);
       
         let uid = firebaseAuth.currentUser.uid;
        try {
          const userRef = db
            .collection("customers").doc(uid).collection("classes")
            .doc(this.selecteditem.id);

          await userRef.update({
            students: this.selecteditem.students,
          });
          // this.viewstudentsDialog=false
          this.newStudent.studentName = "";
           this.newStudent.studentDiff = "";
            this.newStudent.studentEmail = "";
        } catch (error) {
          console.error("Error adding document:", error);
        }
      
    },
    async removeStudent(index) {
      this.selecteditem.students.splice(index, 1);
 let uid = firebaseAuth.currentUser.uid;
      try {
        const userRef = db
          .collection("customers").doc(uid).collection("classes")
          .doc(this.selecteditem.id);

        await userRef.update({
          students: this.selecteditem.students,
        });
      } catch (error) {
        console.error("Error adding document:", error);
      }
    },
     async updateStudent() {
     
 let uid = firebaseAuth.currentUser.uid;
      try {
        const userRef = db
          .collection("customers").doc(uid).collection("classes")
          .doc(this.selecteditem.id);

        await userRef.update({
          students: this.selecteditem.students,
        });
      } catch (error) {
        console.error("Error adding document:", error);
      }
    },


    async AddNewClass() {
      this.isLoading = true;

      let newclass = {...this.newClass};
      let uid = firebaseAuth.currentUser.uid;

      try {
        const userRef = db.collection("customers").doc(uid);
        const subcollectionRef = userRef.collection("classes");
        
        const docRef = await subcollectionRef.add(newclass)
        //console.log(docRef.id);
        // const docRef = await userRef.add(newlicence);
        console.log(docRef);
        this.updateClassList()
        // this.id=docRef.id
      } catch (error) {
        console.error("Error adding document:", error);
      }
      this.isLoading = false;
      this.addDialog = false;
    },

    async updateClassList() {
      this.classes = [...[]];
       let uid = firebaseAuth.currentUser.uid;


      try {
        await db
          .collection("customers").doc(uid).collection("classes")
          .get()
          .then((res) => {
            res.forEach((doc) => {
              // You can add the document ID to the object if needed
              var docData = doc.data();
              docData.id = doc.id;

              // Add the document data to the array
              this.classes.push(docData);
            });
          });
      } catch (error) {
        console.error("Error retreving document:", error);
      }
    },
  },
  mounted() {
  this.updateClassList();
  },
};
</script>
