<template>
           <base-main-container>
    <!-- your content here -->
    <template v-slot:fluid>
        <v-card elevation="0" >
          <v-card-title>
Welcome to our AI-powered Classroom!
          </v-card-title>
          <v-card-text>
 I will help you learn with the guidence of your teacher, lets begin the ai supported learning adventure
<br/>
You can see your assignments below
      
</v-card-text>
        </v-card>
        

    </template>
           </base-main-container>
       
</template>

<script>

export default {
    
  data: () => ({
    
  }),

  
 
}
</script>
<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    .container.fill-height {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
}


</style>

