<template>
  <v-app>
     <component v-bind:is="layout"></component>
  </v-app>
</template>
<script>
import emptylayout from './layouts/emptylayout.vue'
import newface from './layouts/newface.vue'
import studentsLayout from './layouts/studentsLayout.vue';

export default {
  components: {
   emptylayout,
   newface,
   studentsLayout
  },
  computed: {
    layout() {
     
      return this.$route.meta.layout || 'emptylayout'
    },
  },
  created() {
    this.$store.commit('switchTheme/setCurrentTheme')
    this.$vuetify.theme.dark = this.$store.getters['switchTheme/isDarkTheme'];
  },
  methods: {
    
  },
};
</script>
<style>
/* width */
body::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #B8B9C3;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #80868B;
}

</style>
