import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                bodyBgColor: '#ffffff',
                mainContainerBgColor: '#f3f6fc',
                info: "#0B57D0"
            },
            dark: {
                bodyBgColor: '#000000',
                mainContainerBgColor: '#222327',
                info: "#A6C5F7"
            }
        },
    },
});
