import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import studenthome from '../views/studentHome.vue'
import managaLicense from '../views/managa-subscriptions.vue'
import teachform from '../views/teachform.vue'
import editor from '../views/editor.vue'
import communicateform from '../views/communicateform.vue'
import inform from '../views/inform.vue'
import mydocs from '../views/mydocs.vue'
import subsc from '../components/newfacecomponents/subscription.vue'
import landing from '../views/newlanding.vue'
import support from '../components/newfacecomponents/getSupport.vue'
import fileupload from '../views/readUploadedFile.vue'
import privacyPolicy from '../views/privacyPolicy.vue'
import userinput from '../views/useuserdata.vue'
import lessonplan from '../views/lessonplanform.vue'
import newresource from '../views/newresource.vue'
import classes from '../views/MyClass.vue';

import { getCurrentUser } from '../firebase'
Vue.use(VueRouter)

const routes = [

  {
    path: '/home',
    name: 'Home',
    component: home,
    meta: { requiresAuth: true,  layout: 'newface'}
  },
  {
    path: '/studenthome',
    name: 'studentHome',
    component: studenthome,
    meta: { requiresAuth: true, layout: 'studentsLayout' }
  },

  {
    path: '/',
    name: 'Landing',
    component: landing,
    meta: {
      guestOnly: true,
    }
  },
  
{
  path: '/privacyPolicy',
  name: 'privacyPolicy',
  component: privacyPolicy,
    meta: {
      guestOnly: true,
    }
  },
  {
    path: '/fileupload',
    name: 'fileupload',
    component: fileupload,
    meta: {
      guestOnly: true,
    }
  },

  {
    path: '/managelicence',
    name: 'manageLicence',
    component: managaLicense,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/teachform',
    name: 'teachform',
    component: teachform,
    meta: {
      requiresAuth: true,
      layout: 'newface'
    }
  },
  {
    path: '/communicateform',
    name: 'communicateform',
    component: communicateform,
    meta: {
      requiresAuth: true,
      layout: 'newface'
    }
  },
  {
    path: '/inform',
    name: 'inform',
    component: inform,
    meta: {
      requiresAuth: true,
      layout: 'newface'
    }
  },
  {
    path: '/userinput',
    name: 'userinput',
    component: userinput,
    meta: {
      requiresAuth: true,
      layout: 'newface'
    }
  },
  {
    path: '/lessonplan',
    name: 'lessonplan',
    component: lessonplan,
    meta: {
      requiresAuth: true,
      layout: 'newface'
    }
  },
  {
    path: '/mydocs',
    name: 'mydocs',
    component: mydocs,
    meta: {
      requiresAuth: true,
      layout: 'newface'
    }
  },
  {
    path: '/newresource',
    name: 'newresource',
    component: newresource,
    meta: {
      requiresAuth: true,
      layout: 'newface'
    }
  },
  {
    path: '/editor',
    name: 'editor',
    component: editor,
    meta: {
      requiresAuth: true,
      layout: 'newface'
    }
  },
  {
    path: '/classes',
    name: 'classes',
    component: classes,
    meta: {
      requiresAuth: true,
      layout: 'newface'
    }
  },
  {
    path: '/subsc',
    name: 'subsc',
    component: subsc,
    meta: {
      requiresAuth: true,
      layout: 'newface'
    }
  },
  {
    path: '/support',
    name: 'support',
    component: support,
    meta: {
      requiresAuth: true,
      layout: 'newface'
    }
  },
  // routes to new resource creation (apps) pages
  {
    path: '/quiz', name: 'quiz', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/Quiz.vue")
  },
  {
    path: '/lessonplanner', name: 'lessonplanner', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/lessonPlanner.vue")
  },
  {
    path: '/lessonlaunch', name: 'lessonlaunch', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/lessonLaunch.vue")
  },
  {
    path: '/taleforge', name: 'taleforge', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/taleForge.vue")
  },
  {
    path: '/eduscript', name: 'eduscript', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/EduScript.vue")
  },
  {
    path: '/classclown', name: 'classclown', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/ClassClown.vue")
  }, 
{
  path: '/dictionary', name: 'dictionary', meta: { requiresAuth: true, layout: 'newface' },
  component: () => import("../components/apps/Dictionary.vue")
  },
 
  {
    path: '/intervenexpert', name: 'intervenexpert', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/IntervenExpert.vue")
  },
  {
    path: '/parentconnect', name: 'parentconnect', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/ParentConnect.vue")
  },
  {
    path: '/translatepro', name: 'translatepro', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/TranslatePro.vue")
  },
  
  {
    path: '/emailcomposer', name: 'emailcomposer', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/EmailComposer.vue")
  },
  {
    path: '/recommendme', name: 'recommendme', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/RecomendMe.vue")
  },
  {
    path: '/college4all', name: 'college4all', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/College4all.vue")
  },
  {
    path: '/nonfiction', name: 'nonfiction', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/NonFiction.vue")
  },
  {
    path: '/storyreflect', name: 'storyreflect', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/StoryReflect.vue")
  },
  {
    path: '/gamify', name: 'gamify', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/gamification.vue")
  },
  {
    path: '/storyProblems', name: 'storyProblems', meta: { requiresAuth: true, layout: 'newface' },
    component: () => import("../components/apps/StoryProblems.vue")
  },


  { path: '*', redirect: '/' }
]


const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isGuestOnly = to.matched.some(record => record.meta.guestOnly)

  const currentUser = await getCurrentUser()

  if (requiresAuth && !currentUser) {
    next('/')
  } else if (isGuestOnly && currentUser) {
    next('/Home')
  } else {
    next()
  }
})

export default router
