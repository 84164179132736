import Vue from 'vue'
import Vuex from 'vuex'

// Modules import:
import switchDarkLightMode from './modules/switchDarkLightMode/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    switchTheme: switchDarkLightMode
  },
  state: {
    subscription:{},
    edited:{},
    
  },
  mutations: {
    updatesubcribtion(state, payload) {
      state.subscription = payload
    },
    updateedited(state, payload) {
      state.edited = {...payload}
    },
  },
  actions: {
  },
  
})
