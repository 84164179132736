<template>
    <div class="faq-wrapper" id="f-a-q">
        <h1>Frequently Asked Questions</h1>

        <div class="faq-main">
            <v-expansion-panels accordion flat class="v-expansion-panels--custom">
                <v-expansion-panel v-for="(faqItem, i) in faqItems" :key="i">
                    <v-expansion-panel-header>{{ faqItem.question }}</v-expansion-panel-header>
                    <v-expansion-panel-content>{{ faqItem.answer }}</v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
export default {
    name: "FAQComponent",
    data() {
        return {
            selectedFAQ: null,
            faqItems: [
                {
                    id: 1,
                    question: "What Is GPT4Teachers?",
                    answer: `GPT4Teachers.com is a unique platform designed 
                    to assist educators by providing AI-generated educational 
                    resources. Our AI helps in generating lesson plans, quizzes, 
                    parent letters, recommendation letters, responses to emails, 
                    and much more. It's like having a virtual teaching assistant 
                    always ready to help!`
                },
                {
                    id: 2,
                    question: "How Does GPT4Teachers Work?",
                    answer: `GPT4Teachers.com utilizes advanced 
                    AI technology to generate resources based on 
                    your inputs. Simply select the type of resource 
                    you need, provide relevant information (like 
                    topic or grade level for teaching resources), 
                    and our AI will produce a tailor-made resource for 
                    you in seconds. You can then edit, translate, and save 
                    these resources for future use.`
                },
                {
                    id: 3,
                    question: "How accurate are the translations on GPT4Teachers.com?",
                    answer: `GPT4Teachers.com employs cutting-edge AI 
                    technology for translations, providing high-quality 
                    results in numerous languages. However, as with all 
                    automatic translations, there may be some nuances 
                    or idiomatic expressions that are difficult to translate 
                    perfectly. Always remember to review and edit translated 
                    content as needed.`
                },
                {
                    id: 4,
                    question: "Is GPT4Teachers.com secure? What about my data privacy?",
                    answer: `Yes, at GPT4Teachers.com, we prioritize your 
                    privacy and security. We adhere strictly to data 
                    protection laws and regulations. Your data 
                    is used solely for generating the resources you 
                    request and is never shared with third parties.`
                },
                {
                    id: 5,
                    question: "Does GPT4Teachers.com produce accurate information all the time?",
                    answer: `GPT4Teachers.com uses state-of-the-art AI technology, 
                    trained on a broad range of internet text, to generate 
                    educational resources. While it strives to provide 
                    accurate and useful content, it's important to remember 
                    that no AI is perfect. Its output depends on the input it's 
                    given, and it doesn't have the ability to independently 
                    verify facts or information. It's always recommended that 
                    educators review and verify the resources generated, particularly 
                    for factual accuracy and relevancy. As always, the AI is a 
                    tool to assist teachers, but it does not replace the human 
                    touch and professional judgment of educators.`
                }
            ]
        }
    },
    methods: {
        selectFAQ(faqItemId) {
            if (faqItemId === this.selectedFAQ) {
                this.selectedFAQ = null;
                return;
            }

            this.selectedFAQ = faqItemId;
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.faq-wrapper {
    h1 {
        font-size: 3rem;
        margin-bottom: 45px;
        color: $white;
        width: 100%;
        text-align: center;

        @include for-phone-only {
            font-size: 2.25rem !important;
        }
    }

    .faq-main {
        width: 70%;
        margin: 0 auto;

        @include for-tablet-portrait-down {
            width: 100%;
            padding: 0 24px;
        }

        .v-expansion-panels--custom::v-deep {
            .v-expansion-panel {
                background-color: $primaryDark;
                color: $grayLight;
                border-radius: 10px;
                margin-bottom: 1.5rem;
                box-shadow: 0px 10px 35px -8px $primaryLight;
                border-left: 8px solid $grayLight;
                padding: 0 28px;

                @include for-tablet-portrait-down {
                    padding: 0 22px;
                }

                .v-expansion-panel-header {
                    padding: 36px 0;
                    font-size: 24px !important;
                    font-weight: 600;

                    @include for-tablet-portrait-down {
                        padding: 28px 0;
                        font-size: 18px !important;
                        font-weight: 500 !important;
                    }

                    .v-expansion-panel-header__icon {
                        .v-icon {
                            color: $grayLight !important;

                            @include for-tablet-portrait-down {
                                font-size: 18px !important;
                            }
                        }
                    }
                }

                .v-expansion-panel-header--active {
                    color: $pink !important;

                    .v-expansion-panel-header__icon {
                        .v-icon {
                            color: $pink !important;
                        }
                    }
                }

                .v-expansion-panel-content {
                    .v-expansion-panel-content__wrap {
                        padding: 0 0 36px 0 !important;
                        line-height: 1.6 !important;
                        font-size: 18px;

                        @include for-tablet-portrait-down {
                            padding-bottom: 28px !important;
                            font-size: 16px !important;
                            line-height: 1.4 !important;
                        }
                    }
                }
            }

            .v-expansion-panel--active {
                border-left-color: $pink;
            }
        }
    }
}
</style>