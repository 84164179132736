<template>
    <v-footer dark padless>
        <v-card flat tile class="primary footer-wrapper">
            <!-- <v-card-text>
                <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
                    <v-icon size="24px">
                        {{ icon }}
                    </v-icon>
                </v-btn>
            </v-card-text> -->

            <v-container fuid>
                <v-row class="footer-main">
                     <v-col cols="12" md="4"></v-col>
                    <v-col cols="12" md="4">
                        <div class="first-part align-center">
                            <div class="logo align-center">
                                <v-img src="@/assets/gpt4teachers_Logo_xsmall.png" />
                            </div>
                            <div class="support-email align-center">
                                
                                <br/>
                                <v-list style="background-color: transparent">
                                   <v-list-item style="background-color: transparent" to="privacyPolicy" link>
                <v-list-item-content>
                  <v-list-item-title>Privacy Policy</v-list-item-title>
                </v-list-item-content>
              </v-list-item >

                                 <v-list-item style="background-color: transparent">
                                <v-list-item-content>
                  <v-list-item-title>
                     <a href="mailto:support@gpt4teachers.com">Contact Us</a>
                        
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

                                </v-list>
                              
                            
                           
                            </div>
                        </div>
                    </v-col>
                     <v-col cols="12" md="4"></v-col>
                </v-row>
                 <v-row >
 <v-col cols="12" md="4"></v-col>
  <v-col cols="12" md="4">
     <div class="next-part align-center ">
                            <h3>324 Seabrook Dr. Williamsville, NY 14221</h3>
    
                        </div>
  </v-col>
   <v-col cols="12" md="4"></v-col>
                     </v-row>
                  
            </v-container>

            <v-divider></v-divider>

            <v-card-text class="white--text">
                {{ new Date().getFullYear() }} — <strong>GPT4Teachers</strong>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    name: "FooterComponent",
    data: () => ({
        icons: [
            'mdi-facebook',
            'mdi-twitter',
            'mdi-linkedin',
            'mdi-instagram',
        ],
    }),
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.footer-wrapper {
    width: 100%;

    .v-card__text {
        display: flex;
        justify-content: center;
    }

    .footer-main {
        padding: 1rem 1rem;
        justify-content:center;

        .first-part {
            display: flex;
            flex-direction: column;
              justify-content:center;

            .logo {
                width: 60px;
                cursor: pointer;
                margin-bottom: 2rem;
                  justify-content:center;
            }

            .support-email {
                a {
                    text-align: center;
                    text-decoration: none;
                    color: $white !important;
                }
            }
        }

        .next-part {
            display: flex;
            flex-direction: column;

            h3 {
                margin-bottom: 1.5rem;
            }

            a {
                text-decoration: none;
                color: $grayLight;
            }
        }
    }
}
</style>