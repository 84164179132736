<template>
  <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          Your message is processing
        </v-progress-circular>
      </v-overlay>

      <template v-slot:centered>
    
    <v-card v-if="submitted" class="my-card rounded-lg" elevation="0">
        <v-card-text>
           
           <h2> Thank you, your ticket received and we will be contacting you soon.</h2> 
        </v-card-text>
     
    </v-card>

      <v-card v-else class="my-card rounded-lg" elevation="0">

        <v-card-title>What do you need help with
        
        </v-card-title>
     
 <v-card-text>
       
          <v-text-field v-model="contactname" label="Contact Name"></v-text-field>
          <v-text-field v-model="email" label="Contact Email"></v-text-field>
          <v-text-field v-model="problem" label="What do you need?"></v-text-field>
          <v-textarea v-model="message" label="Details"></v-textarea>
          
       
      </v-card-text>
      <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn  @click="submitForm()" color="blue lighten-3" width="150" rounded >Submit</v-btn>
          </v-card-actions>

        
      </v-card>
      </template>
   <v-snackbar
          v-model="showSnackbar"
           :timeout="6000"
           color="warning"
          top
          
        >
          {{ snackbarMessege }}
        </v-snackbar>
  </base-main-container>
</template>

<script>


import { db } from "../../firebase";
export default {
 
  data() {
    return {
      problem:"",
      email: '',
      contactname:"",
      message:"",
isLoading:false,
      showSnackbar:false,
      snackbarMessege:"",
      submitted:false,
     
    };
  },
  
  methods: {
   async submitForm(){
    this.isLoading = true

     let newmail = {
      to: 'support@gpt4teachers.com',
  message: {
  subject: this.problem,
  text: ' ___contact: ' + this.contactname+ '__email: '+
  this.email+'__Problem: '+this.problem+' __Message: '+this.message,
 // html: 'This is the <code>HTML</code> section of the email body.',
}
     }
     
    try {
         const userRef = db.collection("mail");
       
        const docRef = await userRef.add(newmail);
        
        
        console.log(docRef)
       // this.id=docRef.id
       this.isLoading = false
       this.problem="",
      this.email= '',
      this.contactname="",
      this.message="",
      this.submitted= true,
       this.showSnackbar = true
      this.snackbarMessege = "We received your request and will contact you soon"
      } catch (error) {
        console.error("Error adding document:", error);
      this.isLoading = false
      
       this.showSnackbar = true
      this.snackbarMessege = "there was an error, please try again later"
      }
      
      },
   
     
  },
  
};
</script>
<style scoped>

   
    @media (min-width: 600px) {
  .my-card {
     
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
     
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
    
    width: 800px;
  }
}
   
</style>




