<template>
    <div class="main-block">
        <h1> Welcome to the Future  </h1>

        <h2>
              
           <span>AI-Generated  Teaching Resources for Every Class </span> 

        </h2>
<button @click="signInWithGoogle()" type="button" class="login-with-google-btn" >
  Sign in with Google
</button>
        <!-- <div>
            <v-btn @click="signInWithGoogle()">
          <v-img src="@/assets/googleButton/1x/btn_google_signin_light_normal_web.png" ></v-img>
  
             <v-icon class="mr-2">mdi-google</v-icon> Sign in With Google
            </v-btn>
             
        </div> -->
         <h6> No Credit Card Required </h6> 

        <div class="empty_space"></div>
        <v-row class="v-row--custom">
            <v-col>
                <div class="embed-container">
                   <iframe width="560" height="315" src="https://www.youtube.com/embed/bk4EZ8BeE0E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>     </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
import { firebaseAuth } from '../../firebase'
//import BaseButton from '../../ui/BaseButton.vue'
export default {
    components: {
     //   BaseButton
    },

    methods: {
        async signInWithGoogle() {
            
        const provider = new GoogleAuthProvider()

        return signInWithPopup(firebaseAuth, provider)
            .then(() => this.$router.replace({ name: 'Home' }))
    }}
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

// .hover-button .image {
//  background-image: url("/src/assets/googleButton/1x/btn_google_signin_light_normal_web.png");
// }

// .hover-button .image:hover {
//   /* Add styles for the hover image */
//   background-image: url("/src/assets/googleButton/1x/btn_google_signin_light_pressed_web.png");
// }

.main-block {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $grayLight;
    background: radial-gradient(ellipse at center, $primaryLight, $primary 70%);

    h1 {
        font-size: 3.25rem !important;
        line-height: 3.5rem !important;
        margin-bottom: 1.5rem;

        @include for-tablet-portrait-down {
            font-size: 3rem !important;
            line-height: 3rem !important;
            margin-top: 0 !important;

            br {
                display: none;
            }
        }

        @include for-phone-only {
            font-size: 2.25rem !important;
        }

        span {
            @include liner-gradient-style-for-text;
        }
    }
    h2 {
        font-size: 2.25rem !important;
        line-height: 2.5rem !important;
        margin-bottom: 1.5rem;

        @include for-tablet-portrait-down {
            font-size: 2rem !important;
            line-height: 2rem !important;
            margin-top: 0 !important;

            br {
                display: none;
            }
        }

        @include for-phone-only {
            font-size: 1.5rem !important;
        }

        span {
            @include liner-gradient-style-for-text;
        }
    }

    .base-button-wrapper {
        width: 100%;
    }

    p {
        margin-top: 4px;
    }

    .empty_space {
        height: 36px;
    }

    .v-row--custom {
        width: 95%;
    }

   .embed-container {
    position: relative;
    padding-bottom: 37%;
    height: 0;
    overflow: hidden;
    max-width: 65%; /* Adjusted width */
    margin: 0 auto; /* Center the container */

  
}

.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

    .what-is-gpt4teachers {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 16px;

        h1 {
            font-size: 2rem !important;
            color: $white;
            margin-top: 0 !important;
            line-height: 1.4 !important;
        }

        .no-need-list {
            li {
                width: fit-content;
                margin: 16px auto;
                font-size: 1.3rem;
                list-style-type: none;
                padding: 10px 15px;
                border: 1px solid $pink;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                transition: all 0.3s ease-in-out;

                span {
                    margin-left: 10px;
                }

                &:hover {
                    box-shadow: 0 0 8px 3px rgba(255, 255, 255, 0.5);
                }
            }
        }
    }


}

@media screen {}
.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
    
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  
  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  &:active {
    background-color: #eeeeee;
  }
  
  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}

body {
  text-align: center;
  padding-top: 2rem;
}
</style>