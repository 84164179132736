<template>
 <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>

       <template v-slot:centered>
      <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title>Teaching Resources 
        
        </v-card-title>
        <v-card-text>
         
          <!-- <v-select v-if="!premium"
            
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="what"
            :items="[
              'Lesson introduction ideas and activities',
              'Short Story',
              'Joke',
              'Simple Lesson Plan',

              'Presentation Outline',
            ]"
            label="Choose what you wish"
            outlined
            clearable
            @change="clearSelections()"
          >
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
                    Detailed Lesson Plan (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
                    Short Play Script (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
                    Lyrics (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
                    Quiz (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
                    Exit Ticket (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
             
            </template>
          </v-select> -->
          
          <v-select
           
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="what"
            :items="[
              'Lesson introduction ideas and activities',
              'Intervention ideas',
              'Short Story',
              'Short Play Script',
              'Lyrics',
              'Joke',
              'Simple Lesson Plan',
              
              'Presentation Outline', 
              'Quiz',
              'Exit Ticket',
               ]"
            label="Choose what you wish"
            outlined
            clearable
             @change="clearSelections()"
          ></v-select>

          <v-text-field
            
            v-model="topic"
            class="ml-2"
            :label="what == 'Intervention ideas' ? 'Specific behavior or academic area to address':'Topic/Subject'"
            outlined
          />
          <v-select
           
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="grade"
            :items="[
              'K',
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
            ]"
            label="Suitable for Grade level"
            outlined
            clearable
          />

          <v-select
            v-if="what == 'Detailed Lesson Plan with Standards'"
            class="mt-6 ml-2"
            v-model="learningStandards"
            :items="states"
            label="Learning Standards (optional)"
            outlined
            clearable
          ></v-select>
          <v-combobox
            v-if="what == 'Short Story'"
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="tone"
            :items="['Funny', 'Friendly', 'Cold', 'Neutral']"
            label="Tone"
            outlined
            clearable
             @input.native="tone=$event.srcElement.value"
          />
          <v-select
            v-if="what == 'Quiz' || what == 'Exit Ticket'"
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="quizType"
            :items="['Open ended', 'Multiple Choice', 'Short answer']"
            label="Type"
            outlined
            clearable
          />
          <v-select
            v-if="what == 'Quiz' || what == 'Exit Ticket'"
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="quizItemCount"
            :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
            label="Numer of Questions"
            outlined
            clearable
          />

          <v-text-field
            v-if="
              what == 'Short Story' || what == 'Lyrics' || what == 'Short Play Script'
            "
            v-model="genre"
            label="(Optional) Genre, i.e. fairy tale/fantasy/hip hop/etc. "
          />
          <v-text-field
            v-if="what == 'Short Story' || what == 'Short Play Script'"
            v-model="characters"
            label="(Optional) Character names, i.e. John, Eve and Mr.Brown "
          />
          <v-text-field
            v-if="what == 'Short Story' || what == 'Lyrics'"
            v-model="voice"
            label="(Optional) In the voice of , i.e. Mark Twain/Homer Simpson/Michael Jackson etc.)"
          />
          
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn
            :disabled="!((what && topic && grade))
            "
            color="#212121"
            @click="sendRequest()"
          >
            <h3
              v-if="
               !((what && topic && grade))
              "
            >
              Generate please
            </h3>
            <h3 v-else style="color: white">Generate please</h3>
          </v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
       </template>
   <v-snackbar
          v-model="showSnackbar"
          :timeout="6000"
           color="warning"
          top
          
        >
          {{ snackbarMessege }}
        </v-snackbar>
  </base-main-container>
</template>

<script>

import { getFunctions, httpsCallable } from "firebase/functions";

import { firebaseAuth } from "../firebase";
import { db } from "../firebase";

export default {
 
  data() {
    return {
      what: null,
      topic: null,
      grade: null,
      genre: null,
      tone: null,
      characters: null,
      voice: null, 
     // receivedPlan: "",
      isLoading: false,
      language: null,
      quizType: null,
      quizItemCount: null,
     
      learningStandards: null,
      resources:[],
      showSnackbar:false,
      snackbarMessege:"",
      states: [
        "Common Core",
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
    };
  },
  computed:{
   premium(){
      return this.$store.state.subscription ? this.$store.state.subscription.status =='active' 
      || this.$store.state.subscription.status == 'active school'
       || this.$store.state.subscription.status == 'trialing'
      : false
    }
  },

  methods: {
  
    async sendRequest() {
      this.isLoading = true;
     
      let standard =
        this.learningStandards && this.what == "Detailed Lesson Plan with Standards"
          ? ". Please add related " +
            this.learningStandards +
            " learning standards"
          : "";
      let character = this.characters
        ? "with characters " + this.characters + ", "
        : "";
      let genre = this.genre ? "with genre " + this.genre + ", " : "";
      let voice = this.voice ? "from voice " + this.voice + ", " : "";
      let tone = this.tone ? "use " + this.tone + " tone" + ", " : "";

      if (this.what == "Quiz" || this.what == "Exit Ticket") {
        this.what =
          this.quizItemCount && this.quizType
            ? this.quizItemCount + " " + this.quizType + " questions "
            : this.quizItemCount
            ? this.quizItemCount + " questions "
            : this.quizType
            ? this.quizType
            : this.what;
      }
      if (this.what == "Presentation Outline") {
        this.what = "Presentation Outline with bullet points and summaries";
      }

      let prompt =
        
          "Please give me " +
            this.what +
            " on topic: " +
            this.topic +
            ". Please make sure it is suitable for grade level: " +
            this.grade +
            " - " +
            standard +
            " " +
            character +
            " " +
            genre +
            " " +
            voice +
            " " +
            tone 

            // +
            // " " +
            // ". Please give me your answer in rich text format with html tags to enhance the view";

//  ", please give me your answer in rich text format with html tags to enhance the view";
let uid = firebaseAuth.currentUser.uid;
let newresource ={
    what: this.what,
        topic: this.topic,
        grade: this.grade,
        lastsaved: Date.now(), 
        promt: [{ "role": "system", "content": "You are an excellent educator and teacher." },
    { "role": "user", "content": prompt }],
        uid:uid    
}
try {
          const userRef = db.collection("customers").doc(uid);
          const subcollectionRef = userRef.collection("saved");

          const docRef = await subcollectionRef.add(newresource)
          //console.log(docRef.id);
          newresource.id=docRef.id
        } catch (error) {
          console.error("Error adding document:", error);
        }

const functions = getFunctions();

      try { 
      const functionRef = httpsCallable(
        functions,
        "callopenai"
      );
      functionRef(newresource);
       
        this.isLoading = false;
        this.saveDialog = false;
        this.$router.push("/mydocs");

}catch (error) {
      this.isLoading = false;
      this.showSnackbar = true
      this.snackbarMessege = "The system is overloaded, please try again" 
}

      
    },
    
    
    clearSelections() {
      (this.topic = null),
        (this.grade = null),
        (this.genre = null),
        (this.tone = null),
        (this.characters = null),
        (this.voice = null),
        (this.letterTopics = null),
        (this.language = null),
        (this.quizType = null),
        (this.quizItemCount = null),
      
        (this.learningStandards = null);
    },
     
  },
  
};
</script>

<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    @media (min-width: 600px) {
  .my-card {
  
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
   
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
   
    width: 800px;
    
  }
}
    .container.fill-height {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}


</style>




