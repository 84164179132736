<template>
  <v-container>
    <v-card class="pa-6">
      <v-card-title class="headline">Privacy Policy</v-card-title>
      <v-card-text>
        <div v-html="privacyPolicyContent"></div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      privacyPolicyContent: `
  
  
  <p>At GPT4Teachers.com, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard the information you provide to us when using the GPT4Teachers.com app. By using our app, you consent to the practices described in this policy.</p>

  <h3>1. Information We Collect:</h3>

  <h4>1.1 Personal Information:</h4>
  <p>When you use our app, we may collect personal information such as your name, email address, and other contact details. This information is voluntarily provided by you when creating an account or contacting our support team.</p>

  <h4>1.2 Usage Information:</h4>
  <p>We may collect non-personal information about your use of the app, including your device information, IP address, browser type, and usage patterns. This information helps us improve our services and enhance your experience.</p>

  <h3>2. Use of Information:</h3>

  <h4>2.1 Provide Services:</h4>
  <p>We use the information we collect to provide and improve our app, personalize your experience, and develop new features and functionalities.</p>

  <h4>2.2 Communication:</h4>
  <p>We may use your contact information to send you important updates, notifications, and promotional offers related to our services. You have the option to opt out of receiving promotional communications.</p>

  <h4>2.3 Data Analysis:</h4>
  <p>We may analyze aggregated, non-personal data for research and statistical purposes to gain insights into app usage patterns and trends. This helps us improve our app and better understand user needs.</p>

  <h3>3. Data Sharing and Disclosure:</h3>

  <h4>3.1 Service Providers:</h4>
  <p>We may engage trusted third-party service providers to assist us in delivering our app and related services. These providers are bound by confidentiality agreements and are only authorized to use your information to the extent necessary to perform their services.</p>

  <h4>3.2 Legal Requirements:</h4>
  <p>We may disclose your information if required to do so by law or if we believe in good faith that such disclosure is necessary to protect our rights, enforce our terms of service, or comply with a judicial proceeding, court order, or legal process.</p>

  <h3>4. Data Security:</h3>

  <p>We implement industry-standard security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of data transmission or storage is completely secure, and we cannot guarantee the absolute security of your information.</p>

  <h3>5. Children's Privacy:</h3>

  <p>Our app is not intended for children under the age of 13. We do not knowingly collect personal information from individuals under 13 years of age. If we become aware that we have inadvertently collected personal information from a child under the age of 13, we will promptly delete it from our records.</p>

  <h3>6. Changes to this Privacy Policy:</h3>

  <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated policy within the app. We encourage you to review this policy periodically to stay informed about our privacy practices.</p>

  <h3>7. Contact Us: support@gpt4teachers.com </h3>

  <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at support@gpt4teachers.com.</p>

  <p>By using the GPT4Teachers.com, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use our app.</p>
`

    }
  }
}
</script>
