<template>
  <v-container fluid fill-height class="custome-container mainContainerBgColor">
    <div class="container-wrapper">
      <slot></slot>
      <div class="container-centered">
        <div class="centered-inner-block">
          <slot name="centered"></slot>
        </div>
      </div>
      <div class="container-fluid">
        <slot name="fluid"></slot>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "BaseMainContainer"
}
</script>

<style scoped>
.custome-container {
  border-radius: 26px;
  display: flex;
  justify-content: center;
  max-width: 1200px;
  height: 80vh;
  overflow: hidden;
  position: relative;
  /* Adjust this value to match your desired height */

}

.container-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  padding: 24px;
  overflow: auto;
}

.container-centered {
  display: flex;
  justify-content: center;
}

.container-fluid {
  width: 100%;
  border-radius: 18px;
  overflow: hidden;
}
</style>