<template>
  <v-app>

    <nav-component></nav-component>

   <v-main class="primary">
        <v-container fluid>
            <main-block />
            <div class="empty_space"></div>
            <what-can-do />
            <div class="empty_space"></div>
            <how-it-works />
            <!-- <div class="empty_space"></div>
            <testimonials />
             -->
            <div class="empty_space"></div>
            <div class="empty_space"></div>
            <f-a-q />
            <div class="empty_space"></div>
        </v-container>
        <!-- <pricing /> -->
    </v-main>

    <footer-component></footer-component>

  </v-app>
</template>

<script>
import Nav from '../layouts/Nav.vue'

import Footer from '../layouts/Footer.vue';

import MainBlock from '../components/landing/MainSignInBlock.vue';
import WhatCanDo from '../components/landing/WhatCanDo.vue';
import HowItWorks from '../components/landing/HowItWorks.vue';
import FAQ from '../components/landing/FAQ.vue';

export default {
  name: 'App',

  components: {
    'nav-component': Nav,
   
    'footer-component': Footer,
     MainBlock,
        WhatCanDo,
        HowItWorks,
        FAQ
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;600;700;800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "@/assets/scss/style.scss";
* {
  font-family: 'Manrope', sans-serif;
}

html {
  scroll-behavior: smooth;
}
.primary {
     color: $grayLight;
    background: radial-gradient(ellipse at center, $primaryLight, $primary 70%);

}
.empty_space {
    height: 90px;

    @include for-tablet-portrait-down {
        height: 45px !important;
    }
}
</style>