<template>
           <base-main-container>
    <!-- your content here -->
    <template v-slot:fluid>
        <v-card elevation="0" >
          <v-card-title>
Welcome to our AI-powered teacher resource platform!
          </v-card-title>
          <v-card-text>
Your go-to platform for harnessing the power of AI in educational resource creation. We provide user-friendly tools and AI algorithms to help teachers effortlessly generate engaging content tailored to their students' needs. Join our community, revolutionize your teaching, and inspire the next generation. Let's create the future of learning together!
<br/>
You can click  "New Resource" menu on the left to start your journey.
      
</v-card-text>
        </v-card>
        

    </template>
           </base-main-container>
       
</template>

<script>

export default {
    
  data: () => ({
    
  }),

  
 
}
</script>
<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    .container.fill-height {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
}


</style>

