<template>
  <base-main-container>
    <template v-slot:fluid>
<v-toolbar flat class="mainContainerBgColor">
  <v-spacer />
   <div class="select-container">
    <v-select
              class="mt-3 ml-2"
               append-icon="mdi-filter"
              v-model="filter"
              :items="['Teach','Communicate', 'Assess']"
              label="Filter"
              dense
              outlined
              clearable
            />
   </div>
   
            <!-- <v-text-field
            class="ml-6"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
          /> -->
       
</v-toolbar>

 <v-container fluid>
  
  
      <!-- <v-row dense>
        <v-col
          v-for="(item, index) in list" :key="index"
          :cols="4"
        > -->

          <div class="blocks-wrapper">
            <v-col v-for="(item, index) in list" :key="index"
            cols="12" md="6" lg="4" sm="12"
            >

            
            <router-link class="block" :to="item.link" tag="div" >
            <div class="subblock">
                <div class="block-left-side">
                    <v-icon x-large :color="item.color">
                        {{ item.icon }}
                    </v-icon>
                </div>
                <div class="block-right-side">
                    <h3> {{ item.title }}</h3>
                    <p>
                         {{ item.subtitle }}  
                    </p>
                </div>
            </div>
            </router-link>
            </v-col>
          </div>
            
           <!-- <v-card
            :color="item.color"
            dark
            class="mx-5 my-5"
            height="210"
          >
           <v-card-title >
           
           <v-spacer></v-spacer>
              <v-btn
               
                    outlined
                    rounded
                    small
                    link
                    :to="item.link"
              >
                Start
              </v-btn>
             
            </v-card-title>
            <v-card-title class="text-h5">
              {{ item.title }}
            </v-card-title>

            <v-card-subtitle>
            {{ item.subtitle }}  
            </v-card-subtitle>

            <v-card-actions>
             
               <v-spacer></v-spacer>
            </v-card-actions>
          </v-card> -->
      <!-- </v-col>
      </v-row> -->
      </v-container>


 </template>
  </base-main-container>
</template>

<script>
export default {
  data() {
    return {
      filter:null,
      search:"",
      items: [
        {
          title: "QuizCraft",
          subtitle: "Effortless Quiz Creation for Educators",
          link:"quiz",
          color:"blue",
          icon:"mdi-cloud-question",
          group:"Assess"
        },
         {
          title: "TaleForge",
          subtitle: "Crafting Short Stories Made Simple",
          link:"taleforge",
          color:"blue",
          icon:"mdi-summit",
          group:"Teach"
        },
        {
          title: "LessonLaunch",
          subtitle: "Innovative Ideas for Engaging Lesson Introductions",
          link: "lessonlaunch",
          color:"orange",
          icon:"mdi-auto-fix",
          group:"Teach"
             },
        {
          title: "PlanEz",
          subtitle: "Full scale Lesson Plan Generator for Teachers",
          link:"lessonplanner",
          color:"green",
          icon:"mdi-monitor-dashboard",
          group:"Teach"
            },
            {
          title: "GameIfy",
          subtitle: "Create games and engage your students to your lesson",
          link:"gamify",
          color:"red",
          icon:"mdi-gamepad-variant",
          group:"Teach"
            },
            {
          title: "EduScript",
          subtitle: "Subject-Centric Short Play Creator for Educators",
          link:"eduscript",
          color:"orange",
          icon:"mdi-guy-fawkes-mask",
          group:"Teach"
            },
            {
          title: "Story Reflect",
          subtitle: "Read this story. Then answer questions ",
          link:"storyreflect",
          color:"orange",
          icon:"mdi-castle",
          group:"Teach"
            },
             {
          title: "ClassClown",
          subtitle: "Humorous Classroom Jokes Generator",
          link:"classclown",
          color:"purple",
          icon:"mdi-emoticon-happy",
          group:"Teach"
            },
             {
          title: "NonFiction",
          subtitle: "Create non-fiction passages and articles with reading comprehension questions",
          link:"nonfiction",
          color:"purple",
          icon:"mdi-format-float-left",
          group:"Teach"
            },
             {
          title: "Dictionary",
          subtitle: "Define any word in any language",
          link:"dictionary",
          color:"red",
          icon:"mdi-book-open",
          group:"Teach"
            },
           
             {
          title: "Math Fact Tales",
          subtitle: "Story problems for math fluency",
          link:"storyProblems",
          color:"orange",
          icon:"mdi-castle",
          group:"Teach"
            },
            {
          title: "InterveneXpert",
          subtitle: "Personalized Support Strategies for Educators",
          link:"intervenexpert",
          color:"green",
          icon:"mdi-account-convert",
          group:"Teach"
            },
            
            {
          title: "Parent Connect",
          subtitle: "AI assists educators in crafting informative and engaging letters to parents.",
          link:"parentconnect",
          color:"orange",
          icon:"mdi-email-check-outline",
          group:"Communicate"
            },
             {
          title: "TranslatePro",
          subtitle: "Accurate translations for various documents, texts, or conversations.",
          link:"translatepro",
          color:"purple",
          icon:"mdi-globe-model",
          group:"Communicate"
            },
            {
          title: "Email Composer",
          subtitle: "Simplify and enhance your email communication with AI for teachers.",
          link:"emailcomposer",
          color:"blue",
          icon:"mdi-email-plus-outline",
          group:"Communicate"
            },
            {
          title: "Reccomend Me",
          subtitle: "Craft compelling recommendation letters for your students' applications.",
          link:"recommendme",
          color:"green",
          icon:"mdi-thumb-up-outline",
          group:"Communicate"
            },
            {
          title: "College4all",
          subtitle: "Help and guide your studetnt to choose the best College",
          link:"college4all",
          color:"green",
          icon:"mdi-sign-direction",
          group:"Communicate"
            },
            
        // Add more items as needed
      ],
    };
  },
  computed: {
  list (){
    if(this.filter && this.search.length>1){

     return this.items.filter(item => item.group == this.filter)
     .filter(item => item.title.toUpperCase().includes(" "+this.search.toUpperCase())
                                      || item.title.toUpperCase().startsWith(this.search.toUpperCase())) 
    }
    else if(this.filter){
    return this.items.filter(item => item.group == this.filter)
    }
    else if(this.search.length>1){
    return this.items.filter(item => item.title.toUpperCase().includes(" "+this.search.toUpperCase())
                                      || item.title.toUpperCase().startsWith(this.search.toUpperCase())) 
    }
    return this.items
  }
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/style.scss";
.select-container {
  max-width: 300px; /* Adjust this value as needed */
}
 .blocks-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
.subblock {
  padding: 12px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
}
        .block {
          width: 100%;
          height: 120px;
           // max-width: 32%;
            padding: 12px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            // background:#434242;
            box-shadow: 0px 10px 20px -5px #383838;
            border-radius: 20px;
            margin-bottom: 22.5px;
            transition: all 0.3s ease-in-out;

            &:hover {
                transform: translateY(-3px) scale(1.03);
                box-shadow: 0px 25px 45px -10px #434242;
            }

            @media (max-width: 1200px) {
                max-width: 48%;
            }

            @include for-tablet-only {
                max-width: 100%;
            }

            @include for-phone-only {
                max-width: 100%;
            }

            .block-left-side {
                margin-top: 8px;
            }

            .block-right-side {
                margin-left: 12px;

                h3 {
                    line-height: 22px;
                }

                p {
                    font-size: 14px;
                    line-height: 18px;
                    margin-top: 8px;
                }
            }
        }
    }
</style>
