var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-main-container',{scopedSlots:_vm._u([{key:"centered",fn:function(){return [_c('v-card',{staticClass:"my-card rounded-lg",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("I wish to know: ")]),_c('v-card-text',[_c('v-select',{staticClass:"mt-6 ml-2",attrs:{"append-icon":"mdi-filter","items":[
            'What is', 
            'How to',
            'Why is',
            'When was',
            'Where is',
            'Ask anything you want to know'
          ],"label":"Choose what you wish","outlined":"","clearable":""},on:{"change":function($event){return _vm.clearSelections()}},model:{value:(_vm.what),callback:function ($$v) {_vm.what=$$v},expression:"what"}}),(_vm.what)?_c('v-text-field',{staticClass:"ml-2",attrs:{"label":_vm.what == 'What is' ? 
          'Solar eclips, etc.':
          _vm.what == 'How to' ? 
          'hatch chicken eggs, etc.':
          _vm.what == 'What is' ? 
          '':
          _vm.what == 'When was' ? 
          'the paper money used, etc.':
          _vm.what == 'Ask anything you want to know' ? 
          'Feel free to ask me anything':
          _vm.what == 'Where is' ? 
          'Atlantis, etc.':'',"outlined":""},model:{value:(_vm.topic),callback:function ($$v) {_vm.topic=$$v},expression:"topic"}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#212121"},on:{"click":function($event){return _vm.sendRequest()}}},[_c('h3',{staticStyle:{"color":"white"}},[_vm._v("Generate please")])]),_c('v-spacer')],1)],1)]},proxy:true}])},[_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"68"}},[_vm._v(" AI is preparing your resource ")])],1),_c('v-snackbar',{attrs:{"timeout":6000,"color":"warning","top":""},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessege)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }