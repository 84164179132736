<template>
 <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          Please wait
        </v-progress-circular>
      </v-overlay>
       <template v-slot:centered>
        <v-row>
            <v-col>
             <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title><h2> Basic</h2>  </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
<v-list>
    <v-list-item dense v-for="item in basicItems" :key="item.id">
        <v-list-item-icon > <v-icon :color="item.color"> {{item.icon}}</v-icon> </v-list-item-icon>
        <v-list-item-title>{{item.title}}</v-list-item-title>
    </v-list-item>
</v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer />
             <v-btn color="blue lighten-3" width="180" rounded><h3>Free</h3></v-btn>
            <v-spacer />
        </v-card-actions>
      </v-card>    
            </v-col>

            <v-col>
                <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title><h2> Premium </h2>  </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
<v-list>
    <v-list-item dense v-for="item in premiumItems" :key="item.id">
        <v-list-item-icon > <v-icon :color="item.color"> {{item.icon}}</v-icon> </v-list-item-icon>
        <v-list-item-title>{{item.title}}</v-list-item-title>
    </v-list-item>
</v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          
            <v-spacer />
          <v-btn color="blue lighten-3" width="180" rounded @click="createMontlySub()"> <h3>
            
           $7.99/Montly </h3></v-btn>
        

            <!-- <v-btn @click="createMontlySub()" > $7.99/Montly</v-btn> -->
            
            <v-spacer />
        </v-card-actions>
        <v-card-actions>
            <v-spacer />
          <v-btn color="blue lighten-3" width="180" rounded
            @click="createAnnualSub()"
            ><h3> $59.00/Annual</h3></v-btn>
     
             <!-- <v-btn @click="createAnnualSub()" > $59.00/Annual</v-btn> -->
            <v-spacer />
        </v-card-actions>
      </v-card>    
     </v-col>
            <v-col> 
                  <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title><h2> School Licence</h2>  </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
<v-list>
    <v-list-item dense v-for="item in schoolItems" :key="item.id">
        <v-list-item-icon> <v-icon :color="item.color"> {{item.icon}}</v-icon> </v-list-item-icon>
        <v-list-item-title>{{item.title}}</v-list-item-title>
    </v-list-item>
</v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer />
            <v-btn color="blue lighten-3" 
            @click="contactdialog=true"
            width="180" rounded> <h3> Contact Us</h3></v-btn>
            <v-spacer />
        </v-card-actions>
      </v-card>    
            </v-col>
        </v-row>
     
       </template>
        <v-dialog v-model="contactdialog" max-width="500px">
    <v-card>
      <v-card-title>Contact Us</v-card-title>
      <v-card-text>
       
          <v-text-field v-model="schoolname" label="School/District Name"></v-text-field>
          <v-text-field v-model="state" label="State"></v-text-field>
          <v-text-field v-model="contactname" label="Contact Name"></v-text-field>
          <v-text-field v-model="email" label="Contact Email"></v-text-field>
          <v-text-field v-model="numberofteachers" label="Number of Teachers"></v-text-field>
          <v-textarea v-model="message" label="Message"></v-textarea>
          
       
      </v-card-text>
      <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn  @click="submitForm()" color="blue lighten-3" width="150" rounded >Request Quote</v-btn>
          </v-card-actions>
         
    </v-card>
  </v-dialog>
    </base-main-container>
    </template>
    <script>
    import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
} from "firebase/firestore";
import { firebaseAuth } from "@/firebase";
import { db } from "@/firebase";
//import BaseButtonSmall from '../../ui/BaseButtonSmall.vue'
    export default {
   components: {
     //   BaseButtonSmall
    },
  data() {
    return {
      email: '',
      password: '',
      contactdialog: false,
      schoolname:"",
      contactname:"",
      numberofteachers:"",
      message:"",
      state:"",
      
      isLoading: false,
       montlyPrice_code:"price_1MlNCVIuZFpfQ8DRIbX9PShJ",
       annualPrice_code:"price_1MlNDHIuZFpfQ8DR2jcykKTy",
    
      basicItems: [
        {title:"Basic resources", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Basic communication", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Premium resources", icon: "mdi-close-circle-outline", color:"red"},
        {title:"Premium communication", icon: "mdi-close-circle-outline", color:"red"},
        {title:"Translation", icon: "mdi-close-circle-outline", color:"red"},
        {title:"Unlimited Storage", icon: "mdi-close-circle-outline", color:"red"}
      ],
       premiumItems: [
        {title:"Basic resources", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Basic communication", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Premium resources", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Premium communication", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Translation", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Unlimited Storage", icon: "mdi-check-circle-outline", color:"green"},
         {title:"Free Trial", icon: "mdi-check-circle-outline", color:"green"}
      ],
       schoolItems: [
        {title:"Basic resources", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Basic communication", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Premium resources", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Premium communication", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Translation", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Unlimited Storage", icon: "mdi-check-circle-outline", color:"green"},
        {title:"Maintenance-free", icon: "mdi-check-circle-outline", color:"green"},
        {title:"On-demand PD", icon: "mdi-check-circle-outline", color:"green"}
      ]
    }},
   methods: {
     async submitForm(){
    this.isLoading = true

     let newmail = {
      to: 'support@gpt4teachers.com',
  message: {
  subject: this.schoolname,
  text: 'School: '+ this.schoolname +'__state: '+this.state+' ___contact: ' +this.contactname+
  '__email: '+
  this.email+'__number: '+this.numberofteachers+' __Message: '+this.message,
 // html: 'This is the <code>HTML</code> section of the email body.',
}
     }
     
    try {
         const userRef = db.collection("mail");
       
        const docRef = await userRef.add(newmail);
        
        
        console.log(docRef)
       // this.id=docRef.id
       this.isLoading = false
      this.contactdialog = false
       this.showSnackbar = true
      this.snackbarMessege = "We received your request and will contact you soon"
      } catch (error) {
        console.error("Error adding document:", error);
      this.isLoading = false
      this.contactdialog = false
       this.showSnackbar = true
      this.snackbarMessege = "there was an error, please try again later"
      }
      
      },
async createMontlySub() {
      this.isLoading = true;
      const db = getFirestore();
      const collectionRef = collection(
        db,
        "customers",
        firebaseAuth.currentUser.uid,
        "checkout_sessions"
      );

      const docRef = await addDoc(collectionRef, {
        price: this.montlyPrice_code,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();

        if (error) {
          console.error(`An error occured: ${error.message}`);
          this.isLoading = false;
        }

        if (url) {
          window.location.assign(url);
        }
      });
    },
   async createAnnualSub(){
      this.isLoading = true;
      const db = getFirestore();
      const collectionRef = collection(
        db,
        "customers",
        firebaseAuth.currentUser.uid,
        "checkout_sessions"
      );

      const docRef = await addDoc(collectionRef, {
        price: this.annualPrice_code,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();

        if (error) {
          console.error(`An error occured: ${error.message}`);
          this.isLoading = false;
        }

        if (url) {
          window.location.assign(url);
        }
      });
   }

   },
   }
</script>
<style scoped>
.my-card {
    
    width: 300px;
  }
   .base-button-wrapper {
        width: 100%;
    }

    p {
        margin-top: 4px;
    }
</style>
