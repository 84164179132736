<template>
 <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>
       <template v-slot:centered>
      <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title>Communication tools 
        
        </v-card-title>
        <v-card-text>
            <!-- <v-select  v-if="!premium"
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="what"
            :items="[
             'Parent Letter',
              'Translate',
            ]"
            label="Choose what you wish"
            outlined
            clearable
            @change="clearSelections()"
          >
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
                    Translate (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
               Write an Email (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
               Respond to an Email (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-content>
                  <v-list-item-title>
               Recommendation letter (subscriber only)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              
            </template>
          </v-select> -->
          <v-select 
         
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="what"
            :items="[
              'Parent Letter',
              'Translate',
              'Write an Email',
              'Respond to an Email',
              'Recommendation Letter',
              'Discipline Referral',
            ]"
            label="Choose what you wish"
            outlined
            clearable
             @change="clearSelections()"
          ></v-select>
           <v-textarea
           v-if="(what == 'Translate' || what == 'Respond to an Email')"
             v-model="inputText"
            outlined
             :label= "what == 'Translate' ? 'Please enter the tetx to translate' : 'Plese enter the email to be responded'"
          >
          </v-textarea>
         <v-combobox
         v-if=" what =='Translate'"
                class="mx-4 mb-n8"
                  v-model="language"
                  :items=languages
                  label="Type or choose any language to translate"
                  
                  
                   @input.native="language=$event.srcElement.value"
                  
                >
               </v-combobox>
               
          <v-textarea
          v-if="what !=null && what !='Translate'"
             v-model="letterTopics"
            outlined
            :label="what == 'Recommendation Letter' ?
            ' Please give me name, purpose, highlights and specific information to include ':
            'Please tell briefly about what you want to communicate'  "
          >
          </v-textarea>

        </v-card-text>
         <v-combobox
            v-if="what == 'Respond to an Email' || what== 'Parent Letter' || what == 'Write an Email'"
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="tone"
            :items="['Friendly', 'Cold', 'Neutral','Official', 'Legal']"
            label="Tone"
            outlined
            clearable
             @input.native="tone=$event.srcElement.value"
          />
        <v-card-actions>
          <v-spacer />
           

          <v-btn
            :disabled="!letterTopics && !(language && inputText)"
            color="#212121"
            @click="sendRequest()"
          >
            
            <h3  style="color: white">Generate please</h3>
          </v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
  
       </template>
     
   <v-snackbar
          v-model="showSnackbar"
         :timeout="6000"
           color="warning"
          top
        >
          {{ snackbarMessege }}
        </v-snackbar>
</base-main-container>
</template>

<script>

import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseAuth } from "../firebase";
import { db } from "../firebase";
export default {
 
  data() {
    return {
      what: null,
      topic: null,
      grade: null,
       tone: null,
      receivedPlan: "",
      isLoading: false,
    //  title: "",
      letterTopics: null,
      language: null,
      inputText:"",
      resources:[],
     
      showSnackbar:false,
      snackbarMessege:"",
      languages :["Spanish", "French", "German", "Italian", "Russian", "Chinese", "Japanese",
"Korean", "Arabic", "Turkish", "Hindi"],
      
    };
  },
  computed:{
     premium(){
      return this.$store.state.subscription ? this.$store.state.subscription.status =='active' 
      || this.$store.state.subscription.status == 'active school'
       || this.$store.state.subscription.status == 'trialing'
      : false
    }
  },
  methods: {
  
    async sendRequest() {
      this.isLoading = true;
     
        let prompt =""
        if(this.what =='Parent Letter' ){
        prompt = " can you write a parent letter for me about  "+this.letterTopics+" .Please use a "+this.tone+" tone and jargon"
        }else if(this.what =='Translate' ){
        prompt = " can you translate this text to "+this.language + " : " +this.inputText
        }else if(this.what =='Write an Email' ){
        prompt = "can you write and email for me with these points: "+this.letterTopics+" .Please use a "+this.tone+" tone and jargon"
        }else if(this.what =='Respond to an Email' ){
        prompt = 'can you write a respond this email: '+this.inputText +". Please use these points in your response: "+ this.letterTopics+" .Please use a "+this.tone+" tone and jargon"
        }else if(this.what =='Discipline Referral' ){
        prompt = "can you write a Discipline Referral write up  with these points in a paragraph format: "+ this.letterTopics 
        }else if(this.what =='Recommendation Letter' ){
        prompt = " one of my students need a recommendation letter from me. Can you write a Recommendation Letter with this info: "+ this.letterTopics 
        }
    //  prompt = " I am a teacher and I need help with this simple task: "+prompt

      prompt = " I am a teacher and I need help with this simple task, and I need this in rich text format enhaced with html tags, you can add emoji when appropriate "+prompt


let uid = firebaseAuth.currentUser.uid;

let newresource ={
    what: this.what,
        topic: this.topic,
        grade: this.grade,
        lastsaved: Date.now(),
        promt: [{ "role": "system", "content": "You are an excellent educator and teacher." },
    { "role": "user", "content": prompt }],
        uid:uid
        
}
 
try {
          const userRef = db.collection("customers").doc(uid);
          const subcollectionRef = userRef.collection("saved");

          const docRef = await subcollectionRef.add(newresource)
          //console.log(docRef.id);
          newresource.id=docRef.id
        } catch (error) {
          console.error("Error adding document:", error);
        }

const functions = getFunctions();

      try { 
      const functionRef = httpsCallable(
        functions,
        "callopenai"
      );
      functionRef(newresource);
       
        this.isLoading = false;
        this.saveDialog = false;
        this.$router.push("/mydocs");

}catch (error) {
      this.isLoading = false;
      this.showSnackbar = true
      this.snackbarMessege = "The system is overloaded, please try again" 
}


    },
//     async sendRequest_backup() {
//       this.isLoading = true;
     
//         let prompt =""
//         if(this.what =='Parent Letter' ){
//         prompt = " can you write a parent letter for me about  "+this.letterTopics+" .Please use a "+this.tone+" tone and jargon"
//         }else if(this.what =='Translate' ){
//         prompt = " can you translate this text to "+this.language + " : " +this.inputText
//         }else if(this.what =='Write an Email' ){
//         prompt = "can you write and email for me with these points: "+this.letterTopics+" .Please use a "+this.tone+" tone and jargon"
//         }else if(this.what =='Respond to an Email' ){
//         prompt = 'can you write a respond this email: '+this.inputText +". Please use these points in your response: "+ this.letterTopics+" .Please use a "+this.tone+" tone and jargon"
//         }else if(this.what =='Tweet' ){
//         prompt = "can you write a Tweet with these points: "+ this.letterTopics 
//         }else if(this.what =='Recommendation Letter' ){
//         prompt = " one of my students need a recommendation letter from me. Can you write a Recommendation Letter with this info: "+ this.letterTopics 
//         }
// prompt = " I am a teacher and I need help with this simple task, and I need this in rich text format enhaced with html tags so that I can copy paste it to my website."+prompt
         
// let newresource ={
//     what: this.what,
//         topic: this.topic,
//         grade: this.grade,
//         lastsaved: Date.now(),
        
// }
// const functions = getFunctions();

//       try {
//       const functionRef = httpsCallable(
//         functions,
//         "callopenai"
//       );
//       const { data } = await functionRef({
//         promt: prompt,
//       });
 
//  newresource.resource = data.result
//  this.$store.commit("updateedited", newresource);
// this.isLoading = false;
     
//       this.$router.push('/editor'); 
// }catch (error) {
//       this.isLoading = false;
    
//      this.$router.push('/editor'); 
//       this.showSnackbar = true
//       this.snackbarMessege = "The system is overloaded, please try again" 
// }


//     },
    copy() {
      navigator.clipboard.writeText(this.receivedPlan);
    },
    
    clearSelections() {
      (this.topic = null),
        (this.grade = null),
        (this.genre = null),
        (this.tone = null),
        (this.characters = null),
        (this.voice = null),
        (this.letterTopics = null),
        (this.language = null),
        (this.quizType = null),
        (this.quizItemCount = null),
        (this.imgcheck = false),
        (this.learningStandards = null);
    },
     
  },
  
};
</script>
<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    @media (min-width: 600px) {
  .my-card {
    
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
     
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
    
    width: 800px;
  }
}
    .container.fill-height {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}


</style>




