import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import 'vuetify/dist/vuetify.min.css'

import BaseMainContainer from './components/ui/BaseMainContainer.vue';
Vue.component('base-main-container', BaseMainContainer)
Vue.config.productionTip = false

import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
