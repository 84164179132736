import { render, staticRenderFns } from "./MainSignInBlock.vue?vue&type=template&id=930b98c8&scoped=true&"
import script from "./MainSignInBlock.vue?vue&type=script&lang=js&"
export * from "./MainSignInBlock.vue?vue&type=script&lang=js&"
import style0 from "./MainSignInBlock.vue?vue&type=style&index=0&id=930b98c8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "930b98c8",
  null
  
)

export default component.exports