var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-main-container',{scopedSlots:_vm._u([{key:"centered",fn:function(){return [_c('v-card',{staticClass:"my-card rounded-lg",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("Communication tools ")]),_c('v-card-text',[_c('v-select',{staticClass:"mt-6 ml-2",attrs:{"append-icon":"mdi-filter","items":[
              'Parent Letter',
              'Translate',
              'Write an Email',
              'Respond to an Email',
              'Recommendation Letter',
              'Discipline Referral',
            ],"label":"Choose what you wish","outlined":"","clearable":""},on:{"change":function($event){return _vm.clearSelections()}},model:{value:(_vm.what),callback:function ($$v) {_vm.what=$$v},expression:"what"}}),((_vm.what == 'Translate' || _vm.what == 'Respond to an Email'))?_c('v-textarea',{attrs:{"outlined":"","label":_vm.what == 'Translate' ? 'Please enter the tetx to translate' : 'Plese enter the email to be responded'},model:{value:(_vm.inputText),callback:function ($$v) {_vm.inputText=$$v},expression:"inputText"}}):_vm._e(),( _vm.what =='Translate')?_c('v-combobox',{staticClass:"mx-4 mb-n8",attrs:{"items":_vm.languages,"label":"Type or choose any language to translate"},nativeOn:{"input":function($event){_vm.language=$event.srcElement.value}},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}):_vm._e(),(_vm.what !=null && _vm.what !='Translate')?_c('v-textarea',{attrs:{"outlined":"","label":_vm.what == 'Recommendation Letter' ?
            ' Please give me name, purpose, highlights and specific information to include ':
            'Please tell briefly about what you want to communicate'},model:{value:(_vm.letterTopics),callback:function ($$v) {_vm.letterTopics=$$v},expression:"letterTopics"}}):_vm._e()],1),(_vm.what == 'Respond to an Email' || _vm.what== 'Parent Letter' || _vm.what == 'Write an Email')?_c('v-combobox',{staticClass:"mt-6 ml-2",attrs:{"append-icon":"mdi-filter","items":['Friendly', 'Cold', 'Neutral','Official', 'Legal'],"label":"Tone","outlined":"","clearable":""},nativeOn:{"input":function($event){_vm.tone=$event.srcElement.value}},model:{value:(_vm.tone),callback:function ($$v) {_vm.tone=$$v},expression:"tone"}}):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.letterTopics && !(_vm.language && _vm.inputText),"color":"#212121"},on:{"click":function($event){return _vm.sendRequest()}}},[_c('h3',{staticStyle:{"color":"white"}},[_vm._v("Generate please")])]),_c('v-spacer')],1)],1)]},proxy:true}])},[_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"68"}},[_vm._v(" AI is preparing your resource ")])],1),_c('v-snackbar',{attrs:{"timeout":6000,"color":"warning","top":""},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessege)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }