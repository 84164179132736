<template>
    <div class="navbar">
        <v-app-bar  class="v-app-bar--custom" flat>
            <div class="navbar-wrapper">
                <div class="logo">
                    <v-img src="@/assets/gpt4teachers_Logo_xsmall.png" />
                </div>

                <v-spacer></v-spacer>

                <div class="nav-links-wrapper">
                    <a v-for="link in links" :key="link.id" :href="`#${link.id}`">{{ link.title }}</a>
                <v-btn text @click="contactdialog=true" dark> Get School License</v-btn>
                </div>

                <v-spacer></v-spacer>

                <div class="navbar-btn">
                    <v-app-bar-nav-icon @click="drawer = !drawer" ></v-app-bar-nav-icon>
                </div>
            </div>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" absolute temporary left  dark
            class="v-navigation-drawer--custom">
            <div class="navigation-drawer-wrapper">
            
                <div class="logo-wrapper">
                    <div class="logo_">
                        <img src="@/assets/gpt4teachers_Logo_xsmall.png" />
                    </div>
                </div>

                <div class="links-wrapper">
                    <a v-for="link in links" :key="link.id" :href="`#${link.id}`" @click="drawer = false">{{ link.title }}</a>
                </div>
 </div>
        </v-navigation-drawer>
                <v-dialog v-model="contactdialog" max-width="500px">
    <v-card>
      <v-card-title>Contact Us</v-card-title>
      <v-card-text>
       
          <v-text-field v-model="schoolname" label="School/District Name"></v-text-field>
          <v-text-field v-model="state" label="State"></v-text-field>
          <v-text-field v-model="contactname" label="Contact Name"></v-text-field>
          <v-text-field v-model="email" label="Contact Email"></v-text-field>
          <v-text-field v-model="numberofteachers" label="Number of Teachers"></v-text-field>
          <v-textarea v-model="message" label="Message"></v-textarea>
          <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn  @click="submitForm()" color="blue lighten-3" width="150" rounded >Request Quote</v-btn>
          </v-card-actions>
         
       
      </v-card-text>
    </v-card>
  </v-dialog>
   <v-snackbar v-model="showSnackbar" :timeout="6000" color="warning" top>
      {{ snackbarMessege }}
    </v-snackbar>
    </div>
</template>

<script>
 
import { db } from "@/firebase";
export default {
    
    name: "Nav-Component",
    data: () => ({
        showSnackbar:false,
        snackbarMessege:'',
        email: '',
      password: '',
      contactdialog: false,
      schoolname:"",
      contactname:"",
      numberofteachers:"",
      message:"",
      state:"",
      
        drawer: false,
        links: [
            // {
            //     title: "Why GPT4Teachers?",
            //     id: "why-gpt4teachers"
            // },
            {
                title: "What Can Do?",
                id: "what-can-do"
            },
            {
                title: "How It Works",
                id: "how-it-works"
            },
             {
                title: "FAQ",
                id: "f-a-q"
            },
            // {
            //     title: "Pricing",
            //     id: "pricing"
            // }
            
        ],
    }),
    methods: {
     async submitForm(){
    this.isLoading = true

     let newmail = {
      to: 'support@gpt4teachers.com',
  message: {
  subject: this.schoolname,
  text: 'School: '+ this.schoolname +'__state: '+this.state+' ___contact: ' +
  this.email+'__number: '+this.numberofteachers+' __Message: '+this.message,
 // html: 'This is the <code>HTML</code> section of the email body.',
}
}
     
    try {
         const userRef = db.collection("mail");
       
        const docRef = await userRef.add(newmail);
        
        
        console.log(docRef)
       // this.id=docRef.id
       this.isLoading = false
      this.contactdialog = false
       this.showSnackbar = true
      this.snackbarMessege = "We received your request and will contact you soon"
      } catch (error) {
        console.error("Error adding document:", error);
      this.isLoading = false
      this.contactdialog = false
       this.showSnackbar = true
      this.snackbarMessege = "there was an error, please try again later"
      }
    }}
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/style.scss";

.navbar {
    .v-app-bar--custom {
        height: fit-content !important;
        padding: 16px 24px !important;
        color: $grayLight;
    background: radial-gradient(ellipse at center, $primaryLight, $primary 70%);


        .navbar-wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .logo {
                width: 60px;
                cursor: pointer;
            }

            .nav-links-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                a {
                    text-decoration: none;
                    color: $grayLight;
                    margin: 0 16px;
                    font-weight: 500;
                    font-size: 16px;
                    transition: all 0.3s ease-in-out;

                   

                    &:hover {
                        color: $success;
                    }
                }

                @include for-tablet-portrait-down {
                    display: none;
                }
            }

            .navbar-btn {
                display: none;

                @include for-tablet-portrait-down {
                    display: block;
                }
            }
        }
    }
}

.v-navigation-drawer--custom {
    min-width: 50%;

    .navigation-drawer-wrapper {
        width: 100%;
        position: relative;
        padding: 25px;

        .close-button {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
        }

        .logo-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2rem 0;

            .logo_ {
                width: 60px;
                cursor: pointer;

                img {
                    width: 100%;
                }
            }
        }

        .links-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 2rem 0;

            a {
                text-decoration: none;
                color: $grayLight;
                margin-bottom: 1rem;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: $success;
                }
            }
        }
    }
}
</style>