<template>
  <base-main-container>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="68">
        AI is preparing your resource
      </v-progress-circular>
    </v-overlay>

    <template>
      <v-card class="v-card--custom">
        <v-card-title>Your Editable Resource: </v-card-title>

        <v-card-text style="height: 79%">
          <tinymce-editor ref="editor" :api-key="apiKey" :init="initOptions" v-model="editorContent" :key="isDarkTheme" />
        </v-card-text>
<v-card-actions>
  <v-text-field v-model="generateReq" outlined label="Tell me what you need with/from this data" 
  
  >
   <template v-slot:append>   
     <v-icon @click="generate()" color="green"> mdi-arrow-right </v-icon>    
    
  </template>
</v-text-field>
  
</v-card-actions>
       
      </v-card>
     
    </template>

    <v-snackbar v-model="showSnackbar" :timeout="6000" color="warning" top>
      {{ snackbarMessege }}
    </v-snackbar>
  </base-main-container>
</template>

<script>
import { mapGetters } from "vuex";
import Editor from "@tinymce/tinymce-vue";
import { db } from "../firebase";
import { firebaseAuth } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  components: {
    "tinymce-editor": Editor,
  },
  data() {
    return {
      generateReq:"",
      isLoading: false,
      darkTheme: false,
      editorContent: "",
      showSnackbar: false,
      snackbarMessege: "",
     
    };
  },
  
  computed: {
    ...mapGetters('switchTheme', ['isDarkTheme']),
    initOptions() {
      return {
        height: "100%",
        menubar: false,
        /* some plugins and toolbar options */
        skin: this.isDarkTheme ? "oxide-dark" : "oxide",
        content_css: this.isDarkTheme ? "dark" : "default",
        selector: 'textarea',  // change this value according to your HTML
        resize: false,
        toolbar:
          "undo redo | formatselect | fontselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | fullscreen code",
      };
    },
 
  },
  
  methods: {
   async generate(){
   this.isLoading = true;
   let uid = firebaseAuth.currentUser.uid;
let newresource ={
    what: "Generated",
        topic: " ",
        grade: " ",
        lastsaved: Date.now(), 
        promt: [
        { "role": "system", "content": "You are an excellent educator and teacher." },
        { "role": "user", "content": this.editorContent },
        { "role": "assistant", "content":"Thank you providing me this document and information, how can I help you with this, do you need me to produce a teaching resource with this?" },
        { "role": "user", "content": this.generateReq },
        
      
      ],
        uid:uid    
}
try {
          const userRef = db.collection("customers").doc(uid);
          const subcollectionRef = userRef.collection("saved");

          const docRef = await subcollectionRef.add(newresource)
          //console.log(docRef.id);
          newresource.id=docRef.id
        } catch (error) {
          console.error("Error adding document:", error);
        }
        const functions = getFunctions();

      try { 
      const functionRef = httpsCallable(
        functions,
        "callopenai"
      );
      functionRef(newresource);
       
        this.isLoading = false;
        this.saveDialog = false;
        this.$router.push("/mydocs");
        }catch (error) {
      this.isLoading = false;
      this.showSnackbar = true
      this.snackbarMessege = "The system is overloaded, please try again" 
}
   
},
  },
};
</script>
<style scoped>
.v-card--custom {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 18px !important;
  box-shadow: none !important;
}
.v-card--custom .v-card__actions {
  position: relative;
  padding: 16px 0;
  margin-top: auto;
  margin-left: 12px !important;
}

.v-card--custom .v-card__text {
  padding-bottom: 0 !important;
}



</style>